import { CloudUpload } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Variance = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [loading, setLoading] = useState(false);

    const handleGenerate = (file: any) => {
        setLoading(true);
        const formData = new FormData();
        formData?.append("file", file);
        axios
            ?.post("https://demo-bc-api.softsensor.ai/bond_llm/ingest_variance_excel", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    navigate("/variance_generation");
                    setSelectedFile(null);
                }
                setLoading(false);
            });
    };

    return (
        <Box sx={{ width: "50%", py: 3, px: 5, display: "flex", flexDirection: "column", gap: 6 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ mb: 1.5, fontSize: "1.5rem" }}>Variance Analysis Report Generation</Typography>
                <Typography sx={{ color: "#424F65", fontSize: "0.95rem" }}>
                    Upload your excel files and get detailed insights through an analysis report
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    border: "1px solid #f2f2f2",
                    height: "30vh",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {selectedFile ? (
                        <Typography noWrap sx={{ width: "16rem" }}>
                            {selectedFile?.name}
                        </Typography>
                    ) : (
                        <label htmlFor="upload">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    border: "1px solid #007BFFA0",
                                    justifyContent: "center",
                                    gap: 2,
                                    px: 2,
                                    py: 0.4,
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    width: "16rem",
                                }}
                            >
                                <CloudUpload sx={{ color: "#007BFF" }} />
                                <input
                                    id="upload"
                                    hidden
                                    type="file"
                                    onChange={(e: any) => setSelectedFile(e?.target?.files[0])}
                                />
                                Select A File
                            </Box>
                        </label>
                    )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        sx={{
                            textTransform: "capitalize",
                            height: "2.2rem",
                            color: "#007BFF",
                            width: "16rem",
                        }}
                        variant="outlined"
                        onClick={() => {
                            if (selectedFile?.name) {
                                handleGenerate(selectedFile);
                            } else {
                                alert("Please Select A File First");
                            }
                        }}
                    >
                        {loading ? <CircularProgress style={{ width: "25px", height: "25px" }} /> : "Ingest Excel"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Variance;
