// import React from 'react';
// import ReactDOM from 'react-dom';
// import { AzureAD, MsalAuthProvider } from 'react-aad-msal';
// import App from './App';
// import { authProvider } from './screens/Login_page/authProvider';

// const AppWithAzureAD = () => (
//   <AzureAD provider={authProvider}>
//     {({ authenticationState, accountInfo }:any) => {
//       if (authenticationState === 'Authenticated') {
//         return <App />;
//       } else {
//         return <div>Not authenticated. Please sign in.</div>;
//       }
//     }}
//   </AzureAD>
// );

// ReactDOM.render(<AppWithAzureAD />, document.getElementById('root'));

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfig: Configuration = {
    auth: {
        clientId: "9289d2c7-4e1b-45d0-a18b-bda951def517",
        authority: "https://login.microsoftonline.com/358b0b50-5bbc-4f43-9ae9-84e5db58b77b",
        redirectUri: "/",
    },
};
const pca = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
);
reportWebVitals();
