import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import SoftSensorXLogo from "../../components/Common/svgLogs/SoftSensorXLogo";
import { makeStyles } from "@mui/styles";
import QuestionMarkIcon from "../../components/Common/svgLogs/QuestionMarkIcon";
import BellIcon from "../../components/Common/svgLogs/BellIcon";
import ProfileAvatar from "../../components/Common/svgLogs/ProfileAvatar";
import FinDataLogo from "../../assets/FinDataLogo";

import FinData from "../../assets/FinData";
const AppBar = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box sx={{ display: "flex" }}>
                {" "}
                <FinDataLogo />
                <FinData />{" "}
            </Box>
            <Box sx={{ display: "flex" }}>
                <IconButton size="small">
                    <QuestionMarkIcon />
                </IconButton>
                <IconButton size="small">
                    <BellIcon />
                </IconButton>
                <IconButton size="small">
                    <ProfileAvatar />
                </IconButton>
                <Box sx={{ display: "grid", placeItems: "center" }}>
                    <Typography fontSize={"small"}>User Name</Typography>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.3rem 1rem",
        background: "#fff",
        boxShadow: "1px 0px 21px 0px #537CCC14",
        position: "relative",
        zIndex: 3,
    },
});

export default AppBar;
