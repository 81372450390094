import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "./screens/AppBar/AppBar";
import SideBar from "./screens/SideBar";
function AppLayout() {
    const classes = useStyles();

    return (
        <Box className={classes?.container}>
            <AppBar />
            <Box className={classes?.subContainer}>
                <SideBar />
                <Outlet />
            </Box>
        </Box>
    );
}
const useStyles = makeStyles({
    container: {
        height: "100vh",
        background: "#FEFDFD",
        display: "flex",
        flexDirection: "column",
    },
    subContainer: {
        display: "flex",
        flex: 1,
    },
});

export default AppLayout;
