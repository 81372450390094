import { Box, Button, Typography } from "@mui/material";
import React from "react";
 
const Typing = ({ onClick }: { onClick: () => void }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box sx={{ flex: 1 }}>
                <Typography sx={{ color: "#007BFF", fontSize: "0.93rem", fontWeight: 600 }}>
                    Just a moment...
                </Typography>
                <Typography sx={{ color: "#B6B6B6", fontSize: "0.875rem" }}>
                    Generating accurate results for you in no time...
                </Typography>
            </Box>
            <Box>
                <Button
                    sx={{ fontSize: "0.813rem", color: "#555", border: "1px solid #555", px: 2, height: "2.3rem" }}
                    startIcon={
                        <Box
                            sx={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                border: "1px solid #555",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                "&:after": {
                                    content: '""',
                                    width: "50%",
                                    height: "50%",
                                    background: "#555",
                                },
                            }}
                            className="hotBlick"
                        ></Box>
                    }
                    onClick={() => onClick()}
                >
                    Stop
                </Button>
            </Box>
        </Box>
    );
};
 
export default Typing;