import { Box, Typography, OutlinedInput } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../context/setting";

const EnterUserName = ({ userDetails }: { userDetails: any }) => {
    const { contextStore } = useContext<any>(Context);
    const [details, setdetails] = useState<{ userName: string; description: string }>({
        userName: contextStore.temparary?.userName || "",
        description: contextStore.temparary?.description || "",
    });

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 2.5 , width:"35rem", border:"1px solid grey" , borderRadius:5 , padding:3}}>
       
            <Box>
                <Typography variant="h6">Chat Name</Typography>
                
                <OutlinedInput
                    value={details?.userName}
                    sx={{
                        my: 1.5,
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "7.64px",
                            borderColor: "#CFCFCF",
                            overflow: "hidden",
                        },
                        "& .MuiOutlinedInput-input": {
                            height: "0.4rem",
                            // padding: "14px 12px",
                            fontSize: "0.9rem",
                        },
                    }}
                    placeholder="Enter Chat name"
                    onChange={(e: { target: { value: string } }) => {
                        setdetails({ ...details, userName: e.target.value });
                        userDetails.userName = e.target.value;
                    }}
                />
            </Box>
            <Box>
                <Typography variant="h6">Description (Optional)</Typography>
                <OutlinedInput
                    multiline
                    rows={4}
                    value={details?.description}
                    sx={{
                        my: 1.5,
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "7.64px",
                            borderColor: "#CFCFCF",
                            overflow: "hidden",
                        },
                        "& .MuiOutlinedInput-input": {
                            height: "0.4rem",
                            // padding: "14px 12px",
                            fontSize: "0.9rem",
                        },
                    }}
                    onChange={(e: { target: { value: string } }) => {
                        setdetails({ ...details, description: e.target.value });
                        userDetails.description = e.target.value;
                    }}
                />
            </Box>
        </Box>
    );
};

export default EnterUserName;
