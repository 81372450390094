import React from "react";

const DatabaseTypeIcon = () => {
    return (
        <svg width="9" height="20" viewBox="0 0 9 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.83927 7.95913C7.783 3.31097 5.58145 2.07357 5.01985 1.19886C4.78827 0.813526 4.58312 0.41299 4.40582 0C4.37615 0.412925 4.32165 0.673068 3.96978 0.986202C3.2633 1.61454 0.262822 4.05355 0.0103094 9.33486C-0.224955 14.259 3.63931 17.2954 4.14986 17.6092C4.54242 17.8019 5.02054 17.6133 5.25373 17.4364C7.11653 16.1612 9.66166 12.7614 8.84134 7.95913"
                fill="#007BFF"
            />
            <path
                d="M4.56532 14.9863C4.46804 16.2051 4.39836 16.9133 4.15137 17.6098C4.15137 17.6098 4.3135 18.7701 4.42734 19.9992H4.83025C4.92636 19.1337 5.07311 18.2745 5.26973 17.426C4.74815 17.17 4.58533 16.0558 4.56532 14.9863Z"
                fill="black"
            />
            <path
                d="M5.26833 17.4264C4.74123 17.1835 4.58875 16.0459 4.5653 14.986C4.69565 13.2377 4.73365 11.4838 4.67913 9.73155C4.65154 8.8121 4.69224 1.21566 4.45215 0.103516C4.61639 0.480188 4.80561 0.845519 5.01858 1.19708C5.58017 2.07248 7.78241 3.30988 8.838 7.95803C9.66039 12.7521 7.12905 16.1429 5.26833 17.4264Z"
                fill="#AED4FC"
            />
        </svg>
    );
};

export default DatabaseTypeIcon;
