import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Context } from "../context/setting";

import { Add } from "@mui/icons-material";
//import SonftSensorMiniXIcon from "../../components/Common/svgLogs/SonftSensorMiniXIcon";
import { useNavigate } from "react-router-dom";

import FinData from "../assets/FinData";
import LandinglogoIcon from "../components/Common/svgLogs/LandinglogoIcon";
import FinDataLogo from "../assets/FinDataLogo";
import { Grid } from "@mantine/core";
import axios from "axios";

import apiService from "../apis/Api";
import { Api } from "../apis";

const Details = [
    "Customer Email",
    "Customer Name",
    "ID",
    "Name",
    "Status",
    "Plan",
    "Purchase Email",
    "Azure Tenant ID",
];

const Subscription = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [resolve_object, setResolve_Object] = useState<any>(null);
    const [subscribe_status, setSubscribe_Status] = useState<any>(null);
    const [plan, setPlan] = useState<any>(null);
    const instance = useMsal();
    const account = instance?.instance.getAllAccounts()[0];

    axios
        .get("https://saas-api.softsensor.ai/users/v1/user/access-token")
        .then((response) => {
            // access_token=response.data.data.access_token;
            localStorage.setItem("access_token", response.data.data.access_token);
        })
        .catch((error) => {
            console.error("Error:", error);
        });

    useEffect(() => {
        // Api.getUserInfo(instance?.accounts[0].username)?.then((res: any) => {
        //     if (res?.status === 200) setContext({ ...contextStore, userInfo: res?.data });
        //     console.log(res?.data.data[0]?.plans?.[0]);
           
        //     const plan_info = JSON.stringify(res?.data.data[0]?.plans);

        //     localStorage.setItem("plan_info", plan_info);
        // });

        apiService
            .createClient()
            .post("/api/saas/subscriptions/resolve?api-version=2018-08-31")
            .then((res) => {
                setResolve_Object(res?.data);
                console.log(res?.data);
                console.log(resolve_object);
               
            })
            .catch((err) => console.error(err));
    }, []);

    //setResolve_Object([]);

    useEffect(() => {
        const subscriptionInfo = apiService
            .createClient()
            .get(
                `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${resolve_object?.id}/listAvailablePlans?api-version=2018-08-31&planId=${resolve_object?.planId}`
            )
            .then((res) => {
                setPlan(res?.data);



                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Resolve Subscription Successfully  ! ",
                    },
                });
            })
            .catch((err) => console.error(err));
        console.log("error : " + subscriptionInfo);
    }, [resolve_object]);
    //alert(resolve_object?.planId);


    


    const account_info: any = {
        username: instance?.accounts[0].username,
        subs_id: resolve_object?.id,
        plan_id: resolve_object?.planId,
        
    };
    const account_user = JSON.stringify(account_info);

    localStorage.setItem("account_user", account_user);

    const formatDateWithoutTime = (dateString: any) => {
        const date = new Date(dateString);
        return date.toISOString().split("T")[0];
    };

    const Redirect_subscriptions = (subscription_id: any) => {
        // alert('90383081-a95a-473c-cce2-ed8b92b429de' + 'meter-api-testing')
        // const planinfo = apiService
        //     .createClient()
        //     .get(
        //         `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscription_id}/listAvailablePlans?api-version=2018-08-31&planId=${plan_id}`
        //     )
        //     .then((res) => {
        //         console.log(res?.data);
        //         alert("status Code : 200" + "Plan Details fetched");
        //     })
        //     .catch((err) => console.error(err));
        // console.log("error : " + planinfo);
        const d1 = plan?.plans[0]?.planComponents?.recurrentBillingTerms[0]?.meteredQuantityIncluded[0]?.units;
        const d2 = plan?.plans[0]?.planComponents?.recurrentBillingTerms[0]?.meteredQuantityIncluded[1]?.units;
        console.log(parseInt(d1, 10) + '!=' + parseInt(d2, 10));
       
        let pagecount = null;
        let tokencount = null;
        if(parseInt(d1, 10)<=parseInt(d2, 10)){
            pagecount = d1; 
            tokencount = d2;
        }else {
            pagecount = d2; 
            tokencount = d1;
        }
        console.log(pagecount+'!='+tokencount);

        Api.addUserInfoToDB({
            email: account?.username,
            accessToken: localStorage.getItem("access_token"),
            resourceId: resolve_object?.id,
            quantity: tokencount,
            dimension: "token_v1",
            planId: resolve_object?.planId,
            numberOfPages: pagecount,
        })?.then((res: any) => {
            if (res?.status === 200)
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Data saved successfully",
                    },
                });
        });

        const subscriptionInfo = apiService
            .createClient()
            .get(
                `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${resolve_object?.id}/listAvailablePlans?api-version=2018-08-31&planId=${resolve_object?.planId}`
            )
            .then((res) => {
                console.log(res?.data);
                //alert(" Redirecting to Findata chatbhot ...... !");
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Successfully Redirecting to Findata Chatbot",
                    },
                });
                navigate("/plan_page");
            })
            .catch((err) => console.error(err));
        console.log("error : " + subscriptionInfo);
    };

    const Activate_subscription = (subscription_id: any) => {
       
        
        const subscription_Response = apiService
            .createClient()
            .post(
                `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscription_id}/activate?api-version=2018-08-31`
            )
            .then((res) => {
                console.log(res?.data);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message:
                            "Plan Activation request sent to Azure AD ! Please wait Or Come Back later after 10-15mins , then it should refresh and update the page",
                    },
                });
                setSubscribe_Status(true);
                //navigate("/landing_page");
            })
            .catch((err) => console.error(err));
        console.log("error : " + subscription_Response);

        Api.createNewUser({
            firstName: account?.name,
            lastName: "",
            email: resolve_object?.subscription?.purchaser?.emailId,
            tenantId: resolve_object?.subscription?.purchaser?.tenantId,
            subscriptionId: resolve_object?.id,
        })?.then((res: any) => {
            if (res?.status === 200)
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "User added successfully",
                    },
                });
        });
        // const d1 = plan?.plans[0]?.planComponents?.recurrentBillingTerms[0]?.meteredQuantityIncluded[0]?.units;
        // const d2 = plan?.plans[0]?.planComponents?.recurrentBillingTerms[0]?.meteredQuantityIncluded[1]?.units;
        // console.log(d1 + '!=' + d2);
        // let pagecount = '';
        // let tokencount = '';
        // if(d1<d2){
        //     pagecount = d1; 
        //     tokencount = d2;
        // }else {
        //     pagecount = d2; 
        //     tokencount = d1;
        // }
        // console.log(pagecount+'!='+tokencount);

        // Api.addUserInfoToDB({
        //     email: account?.username,
        //     accessToken: localStorage.getItem("access_token"),
        //     resourceId: resolve_object?.id,
        //     quantity: tokencount,
        //     dimension: "token_v1",
        //     planId: resolve_object?.planId,
        //     numberOfPages: pagecount,
        // })?.then((res: any) => {
        //     if (res?.status === 200)
        //         setContext({
        //             ...contextStore,
        //             snackState: {
        //                 open: true,
        //                 errorType: "success",
        //                 message: "Data saved successfully",
        //             },
        //         });
        // });
    };

    const showConfirmation = () => {
        return window.confirm('Are you sure to Cancel the subscription ?');
      };

    const Cancel_subscription = (subscription_id: any) => {
        
        if (showConfirmation()){
            const subscription_Response = apiService
            .createClient()
            .delete(
                `https://marketplaceapi.microsoft.com/api/saas/subscriptions/${subscription_id}?api-version=2018-08-31`
            )
            .then((res) => {
                console.log(res?.data);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message:
                            "Subcription Deactivated",
                    },
                });
                 setSubscribe_Status(false);
                //navigate("/landing_page");
            })
            .catch((err) => console.error(err));
        console.log("error : " + subscription_Response);
        }


      

      

        // Api.addUserInfoToDB({
        //     email: account?.username,
        //     accessToken: localStorage.getItem("access_token"),
        //     resourceId: resolve_object?.id,
        //     quantity: tokencount,
        //     dimension: "token_v1",
        //     planId: resolve_object?.planId,
        //     numberOfPages: pagecount,
        // })?.then((res: any) => {
        //     if (res?.status === 200)
        //         setContext({
        //             ...contextStore,
        //             snackState: {
        //                 open: true,
        //                 errorType: "success",
        //                 message: "Data saved successfully",
        //             },
        //         });
        // });
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "85vh",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    
                    // alignItems: "center", //center
                    // justifyContent: "center", //center
                    width: "55%",
                    //height: "90%",
                    maxWidth: "1050px",
                    flexShrink: 0,
                    background: "#FDFDFD",
                    // marginTop: "10px",
                    // marginLeft: "140px",
                    padding: "40px",
                }}
            >
                <Typography
                    sx={{
                        color: "#3D3232",
                        fontFamily: "Inter",
                        fontSize: "22px",
                        fontWeight: 600,
                    }}
                >
                    Subscription Details
                </Typography>
                {contextStore?.userInfo?.data.length === 0 && !resolve_object ? (
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", //center
                            justifyContent: "center", //center
                            width: "55%",

                            background: "#FDFDFD",
                            marginTop: "100px",
                            marginLeft: "120px",
                            paddingLeft: "60px",
                            // padding:'40px'
                        }}
                    >
                        <Grid.Col span={2} sx={{ marginLeft: 30 }}>
                            <Box marginLeft={3}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="68"
                                    height="68"
                                    viewBox="0 0 108 108"
                                    fill="none"
                                >
                                    <g clip-path="url(#clip0_4_85)">
                                        <path
                                            d="M46.8267 73H55.8267V82H46.8267V73ZM46.8267 37H55.8267V64H46.8267V37Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M19.1206 82.1407C32.5209 100.215 57.7006 103.954 75.7749 90.5539C84.4506 84.1217 90.3528 74.7042 91.9419 64.0028L82.4995 62.6006C81.5523 71.1455 77.2831 78.232 70.4148 83.3242C48.0026 99.9406 16.3346 81.4053 20.8092 53.4396C25.2838 25.4739 60.342 16.8475 76.9584 39.2596L66.1138 47.2998L91.2936 51.039L91.655 50.771L95.0328 25.8593L84.1882 33.8995C70.7878 15.8252 45.6081 12.086 27.5338 25.4863C9.45946 38.8866 5.72026 64.0663 19.1206 82.1407Z"
                                            fill="black"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4_85">
                                            <rect width="108" height="108" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={8}>: You do not have any subscription</Grid.Col>

                        {/* <Grid.Col span={10} sx={{ marginTop: 60 }}>
                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    width: "80%",
                                    border: "1px solid #C4C7C9",
                                    background: "#1B75BC",
                                }}
                            // onClick={Activate_subscription}
                            >
                                <Typography
                                    sx={{
                                        color: "#3D3232",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                    }}
                                >
                                    Subscribe to this application
                                </Typography>
                            </Button>
                        </Grid.Col> */}
                    </Grid>
                ) : (
                    <div>
                        {" "}
                        <Grid
                            sx={{
                                display: "flex",
                                //flexDirection: "column",
                                alignItems: "start", //center
                                justifyContent: "center", //center
                                width: "100%",
                                background: "#FDFDFD",
                                marginTop: "20px",
                            }}
                        >
                            <Grid.Col span={4}>Customer Email </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object?.subscription?.purchaser?.emailId}</Grid.Col>
                            <Grid.Col span={4}>Customer Name </Grid.Col>
                            <Grid.Col span={6}>: {account?.name} </Grid.Col>
                            <Grid.Col span={4}>ID </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object?.id}</Grid.Col>
                            <Grid.Col span={4}>Subscription Name </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object?.subscriptionName}</Grid.Col>
                            <Grid.Col span={4}>Status </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object?.subscription?.saasSubscriptionStatus}</Grid.Col>

                            <Grid.Col span={4}>Plan Id </Grid.Col>
                            <Grid.Col span={6}>
                                :{" "}
                                <Button
                                    sx={{
                                        borderRadius: "10px",
                                        width: "250px",
                                        border: "1px solid #C4C7C9",
                                        background:
                                            "linear-gradient(96deg, #9DA2A6 -3.63%, #9DA2A6 -3.62%, rgba(114, 121, 125, 0.14) 7.12%, rgba(151, 156, 160, 0.57) 29.91%, rgba(151, 156, 160, 0.57) 29.92%, rgba(246, 246, 246, 0.00) 117.87%)",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#3D3232",

                                            fontSize: "14px",

                                            fontWeight: 600,
                                        }}
                                    >
                                        {resolve_object?.planId}
                                    </Typography>
                                </Button>
                            </Grid.Col>
                            {/* <Grid.Col span={4}>Start Date - End Date </Grid.Col> */}
                            {/* <Grid.Col span={6}>
                                : {formatDateWithoutTime(item?.start_date)} / {formatDateWithoutTime(item?.end_date)}
                            </Grid.Col> */}

                            <Grid.Col span={4}>Azure Tenant ID </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object?.subscription?.purchaser?.tenantId}</Grid.Col>

                            {resolve_object?.subscription?.saasSubscriptionStatus ==="Subscribed" ? (
                                <>
                                <Grid.Col span={10}>
                                    <Button
                                        sx={{
                                            borderRadius: "10px",
                                            width: "100%",
                                            border: "1px solid #C4C7C9",
                                            background: "#FFF",
                                        }}
                                        onClick={() => Redirect_subscriptions(resolve_object?.id)}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#3D3232",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Get Started
                                        </Typography>
                                    </Button>
                                </Grid.Col>
                                    <Grid.Col span={10}>
                                        <Button
                                             onClick={() => Cancel_subscription(resolve_object?.id)}
                                            sx={{
                                                borderRadius: "10px",
                                                width: "100%",
                                                border: "1px solid #C4C7C9",
                                                background: "#1B75BC",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#3D3232",
    
                                                    fontSize: "14px",
    
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Unsubscribe
                                            </Typography>
                                        </Button>
                                    </Grid.Col>
                                    </>
                            ) : (
                                <Grid.Col span={10}>
                                <Button
                                    sx={{
                                        borderRadius: "10px",
                                        width: "100%",
                                        border: "1px solid #C4C7C9",
                                        background: "#1B75BC",
                                    }}
                                    onClick={() => Activate_subscription(resolve_object?.id)}
                                >
                                    <Typography
                                        sx={{
                                            color: "#3D3232",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Activate Subscription
                                    </Typography>
                                </Button>
                            </Grid.Col>
                            )}

                           
                        </Grid>
                    </div>
                )}
            </Box>
        </Box>
    );
};

export default Subscription;

// useEffect(() => {
//     const Resolve: any = localStorage.getItem("resolve_subscription");

//     if (Resolve) {
//         const resolveObject = JSON.parse(Resolve);

//         const resolvedItems = Object.keys(resolveObject).map((key) => ({
//             [key]: resolveObject[key],
//         }));

//         if (resolvedItems[3]) {
//             setResponseStatus(true);
//             const res_object = [
//                 {
//                     Id: resolvedItems[0]?.id,
//                     Subs_Name: resolvedItems[1]?.subscriptionName,
//                     Plan_id: resolvedItems[3]?.planId,
//                     email_id: resolvedItems[4]?.subscription.purchaser.emailId,
//                     tenant_id: resolvedItems[4]?.subscription.purchaser.tenantId,
//                     // start_date: resolvedItems[4].subscription.term.startDate,
//                     // end_date: resolvedItems[4].subscription.term.endDate,
//                     saas_status: resolvedItems[4]?.subscription.saasSubscriptionStatus,
//                 },
//             ];
//             console.log(res_object);
//             setResolve_Object(res_object);
//         }
//     } else {
//         console.log("Object not found in localStorage");
//     }
//     //console.log(marketPlaceToken);
// }, []);
