import React, { useEffect } from "react";
//import { Link, useNavigate } from "react-router-dom";

//import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import {
    AppBar,
    Avatar,
    Box,
    Tab,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import FinData from "../assets/FinData";
import FinDataLogo from "../assets/FinDataLogo";
import Landing_Page from "./Landing";

import MenuIcon from "@mui/icons-material/Menu";

import AdbIcon from "@mui/icons-material/Adb";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { Col, Grid } from "@mantine/core";
import Subscription from "./Subscription";
import Account_Page from "./Account";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const pages = ["Products", "Pricing", "Blog"];

const Main_page = () => {
    const [error, setError] = React.useState("");
    const [submittedValues, setSubmittedValues] = React.useState("");
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const theme = useTheme();
    const [tabvalue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setTabValue(index);
    };

    useEffect(() => {});

    return (
        <Box sx={{ bgcolor: "rgba(67, 99, 109, 0.06)", width: "100%" }}>
            <AppBar position="static" sx={{ backgroundColor: "#FFF" }}>
                <Grid>
                    <Grid.Col span={7} mx={50} mt={2}>
                        {" "}
                        <FinDataLogo /> <FinData />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {" "}
                        <Tabs
                            value={tabvalue}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab
                                label="Homepage"
                                sx={{ color: "#1B75BC", fontWeight: 600, fontSize: "12px" }}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label="Subscription"
                                sx={{ color: "#1B75BC", fontWeight: 600, fontSize: "12px" }}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label="Account"
                                sx={{ color: "#1B75BC", fontWeight: 600, fontSize: "12px" }}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Grid.Col>
                    {/* <Grid.Col span={2}>
           
</Grid.Col> */}
                </Grid>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={tabvalue}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tabvalue} index={0} dir={theme.direction}>
                    <Landing_Page />
                </TabPanel>
                <TabPanel value={tabvalue} index={1} dir={theme.direction}>
                    <Subscription />
                </TabPanel>
                <TabPanel value={tabvalue} index={2} dir={theme.direction}>
                    <Account_Page />
                </TabPanel>
            </SwipeableViews>

            {/* Add a modal for success message */}
        </Box>
    );
};

export default Main_page;
