import { useMsal } from "@azure/msal-react";
import { Button, Grid } from "@mui/material";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
    const { instance } = useMsal();
    const account = instance.getAllAccounts()[0];
    if (account) {
        <Navigate to="/get_started" />;
    }
    const initializeSignIn: any = () => {
        instance.loginRedirect();
    };

    localStorage.setItem("url", window.location.href);

    return (
        <Grid container justifyContent="center" height="100vh" alignItems="center" direction="column">
            Click on this button to login to this application
            <Button onClick={initializeSignIn} variant="contained" size="small">
                Sign in
            </Button>
        </Grid>
    );
};

export default LoginPage;
