import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Context } from "../context/setting";

import Paper from '@mui/material/Paper';

import { useNavigate } from "react-router-dom";


import { Grid } from "@mantine/core";
import axios from "axios";

import apiService from "../apis/Api";
import { Api } from "../apis";



const Plan_info = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [resolve_object, setResolve_Object] = useState<any>(null);
    const [subscribe_status, setSubscribe_Status] = useState<any>(null);
    const [plan, setPlan] = useState<any>(null);
    const instance = useMsal();
    const account = instance?.instance.getAllAccounts()[0];

    // useEffect(() => {
    //     const plan_info_str = localStorage.getItem("plan_info");
    //     const plan_info = localStorage.getItem("account_user");
    //     let plan_name = '';
    //     if (plan_info !== null) {

    //         const plan_info_json = JSON.parse(plan_info);
    //         plan_name = plan_info_json.plan_id;
    //         console.log(plan_name);
    //          }




    //     if (plan_info_str !== null) {
    //         let plan_info = JSON.parse(plan_info_str);

    //         if (Array.isArray(plan_info)) {
    //             // Filter the array
    //             const filteredPlanInfo = plan_info.filter((item:any) => item.planId === plan_name);
    //             console.log("Filtered Plan Info:", filteredPlanInfo);

    //             // Convert the filtered array back to a JSON string
    //             const filteredPlanInfoStr = JSON.stringify(filteredPlanInfo[0]);
    //             console.log("Filtered Plan Info String:", filteredPlanInfoStr);

    //             // Save the filtered array back to localStorage
    //             localStorage.setItem("plan_info", filteredPlanInfoStr);
    //         } else {
    //            console.log("Parsed JSON is not an array.");
    //         }   

    //         //const filteredPlanInfo = plan_info.filter((item: any) => item.planId === plan_name);


    //     }else {
    //         alert("plan info having null value");
    //     }

    // }, []);

    useEffect(() => {
        Api.getUserInfo(instance?.accounts[0].username)?.
            then((res: any) => {
                if (res?.status === 200) setContext({ ...contextStore, userInfo: res?.data });
                console.log(res?.data.data[0]?.plans?.[0]);

                //const plan_info = JSON.stringify(res?.data.data[0]?.plans);
                const plan_info = res?.data.data[0]?.plans;

                const account_user = localStorage.getItem("account_user");
                    
                let plan_id = '';
                let subs_id = '';
                if (account_user !== null) {
        
                    const plan_info_json = JSON.parse(account_user);
                    plan_id = plan_info_json.plan_id;
                    subs_id = plan_info_json.subs_id;
                    console.log(plan_id + '::' + subs_id);
                    }
            
            
            

                if (plan_info !== null) {
                    // Filter the array
                    const filteredPlanInfo = plan_info.filter((item: any) => item.resourceId === subs_id );

                    setResolve_Object(filteredPlanInfo);
                    //console.log(resolve_object[0].dimension);
                    const filteredPlanInfoStr = JSON.stringify(filteredPlanInfo[0]);



                    localStorage.setItem("plan_info", filteredPlanInfoStr);

                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message:
                                "Dimension Updated",
                        },
                    });
                } else {
                    
                     console.log("Parsed JSON is not an array.");
                }

            });

    }, []);

    //setResolve_Object([]);



    return (
        <Box
            sx={{
                width: "100%",
                height: "85vh",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",

                    // alignItems: "center", //center
                    // justifyContent: "center", //center
                    width: "65%",
                    //height: "90%",
                    maxWidth: "1050px",
                    flexShrink: 0,
                    background: "#FDFDFD",
                    // marginTop: "10px",
                    // marginLeft: "140px",
                    padding: "40px",
                }}
            >
                <Typography
                    sx={{
                        color: "#3D3232",
                        fontFamily: "Inter",
                        fontSize: "22px",
                        fontWeight: 600,
                    }}
                >
                    Plan Details
                </Typography>
                {!resolve_object ? (
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", //center
                            justifyContent: "center", //center
                            width: "65%",

                            background: "#FDFDFD",
                            marginTop: "100px",
                            marginLeft: "120px",
                            paddingLeft: "60px",
                            // padding:'40px'
                        }}
                    >
                        <Grid.Col span={2} sx={{ marginLeft: 30 }}>
                            <Box marginLeft={3}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="68"
                                    height="68"
                                    viewBox="0 0 108 108"
                                    fill="none"
                                >
                                    <g clip-path="url(#clip0_4_85)">
                                        <path
                                            d="M46.8267 73H55.8267V82H46.8267V73ZM46.8267 37H55.8267V64H46.8267V37Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M19.1206 82.1407C32.5209 100.215 57.7006 103.954 75.7749 90.5539C84.4506 84.1217 90.3528 74.7042 91.9419 64.0028L82.4995 62.6006C81.5523 71.1455 77.2831 78.232 70.4148 83.3242C48.0026 99.9406 16.3346 81.4053 20.8092 53.4396C25.2838 25.4739 60.342 16.8475 76.9584 39.2596L66.1138 47.2998L91.2936 51.039L91.655 50.771L95.0328 25.8593L84.1882 33.8995C70.7878 15.8252 45.6081 12.086 27.5338 25.4863C9.45946 38.8866 5.72026 64.0663 19.1206 82.1407Z"
                                            fill="black"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4_85">
                                            <rect width="108" height="108" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={8}>: You do not have any Active Plan</Grid.Col>


                    </Grid>
                ) : (
                    <div>
                        {" "}
                        <Grid
                            sx={{
                                display: "flex",
                                //flexDirection: "column",
                                alignItems: "start", //center
                                justifyContent: "center", //center
                                width: "100%",
                                background: "#FDFDFD",
                                marginTop: "20px",
                                color: "#3D3232",
                                fontFamily: "Inter",
                                fontSize: "19px",
                                fontWeight: 500,
                            }}
                        >
                            <Grid.Col span={4}>Account Email </Grid.Col>
                            <Grid.Col span={6}>: {account?.username}</Grid.Col>

                            <Grid.Col span={4}>User Name </Grid.Col>
                            <Grid.Col span={6}>: {account?.name} </Grid.Col>

                            <Grid.Col span={4}>Subscription ID </Grid.Col>
                            <Grid.Col span={6}>: {resolve_object[0]?.resourceId}</Grid.Col>

                            <Grid.Col span={4}>Plan Id</Grid.Col>
                            <Grid.Col span={6}>
                                
                                <Typography
                                    sx={{
                                        color: "#3D3232",

                                        fontSize: "16px",

                                        fontWeight: 600,
                                    }}
                                >
                                    : {resolve_object[0]?.planId}
                                </Typography>
                            </Grid.Col>

                            <Grid.Col span={4}>Plan Status </Grid.Col>
                            <Grid.Col span={6}>: Active</Grid.Col>

                            <Grid.Col span={12} >
                                <Typography
                                    sx={{
                                        color: "#3D3232",
                                        fontFamily: "Inter",
                                        fontSize: "19px",
                                        fontWeight: 600,
                                        display: "flex",

                                        alignItems: "center", //center
                                        justifyContent: "center", //center
                                    }}
                                >
                                    Dimension Stats
                                </Typography></Grid.Col>

                            <Grid.Col span={12}>  <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{fontFamily: "Inter", fontSize: "16px", fontWeight: 600,}}>
                                            <TableCell> Dimension </TableCell>
                                            <TableCell align="right">Total Count </TableCell>
                                            <TableCell align="right">Consumed Count </TableCell>
                                            <TableCell align="right">Balance Count</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow

                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Token_T1
                                            </TableCell>
                                            <TableCell align="right" sx={{color:"blue"}}>{resolve_object[0]?.numberOfTokens}</TableCell>
                                            <TableCell align="right" sx={{color:"red"}}>{resolve_object[0]?.tokenQuotaConsumed}</TableCell>
                                            
                                            <TableCell align="right" sx={{color:"green"}}>
                                                {resolve_object[0]?.numberOfTokens - resolve_object[0]?.tokenQuotaConsumed}</TableCell>

                                        </TableRow>
                                        <TableRow

                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                Page_P1
                                            </TableCell>
                                            <TableCell align="right" sx={{color:"blue"}}>{resolve_object[0]?.numberOfPages}</TableCell>
                                            <TableCell align="right" sx={{color:"red"}}>{resolve_object[0]?.pagesQuotaConsumed}</TableCell>
                                            
                                            <TableCell align="right" sx={{color:"green"}}>
                                                {resolve_object[0]?.numberOfPages - resolve_object[0]?.pagesQuotaConsumed}</TableCell>

                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </Grid.Col>





                        </Grid>
                    </div>
                )}
            </Box>
        </Box >
    );
};

export default Plan_info;


