import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import { Box, Typography } from "@mui/material";
import Typing from "./Typing";

const MessageBox = ({ varianceSocketHooks, varianceMsgRef }: { varianceSocketHooks: any; varianceMsgRef: any }) => {
    const [msgList, setMsgList] = useState<any[]>([{ out: true, msg: "What Can I Help You!", latest: false }]);
    const [loading, setLoading] = useState<boolean>(false);

    const handelSend = (value: string) => {
        varianceMsgRef.load = true;
        setMsgList((prev) => [...prev, { out: true, msg: value, latest: false }, { in: true, msg: "", latest: true }]);
        varianceSocketHooks?.sendJsonMessage({ query: value });
    };

    useEffect(() => {
        setLoading(() => (varianceMsgRef?.load ? true : false));
        if (varianceMsgRef?.load === false) {
            setMsgList((prev: any[]) => {
                return prev?.map((message) => {
                    if (message?.latest) {
                        message.latest = false;
                        message.msg = varianceMsgRef?.msg;
                        varianceMsgRef.msg = "";
                    }
                    return message;
                });
            });
        }
    }, [varianceMsgRef?.load]);

    return (
        <Box
            sx={{
                p: 1.5,
                display: "flex",
                flexDirection: "column",
                transition: "250ms ease-in",
                justifyContent: "space-between",
                gap: 1,
                height: "100%",
                alignItems: "center",
            }}
        >
            <Box sx={{ height: "75vh", width: "90%", overflowY: "auto" }}>
                {msgList?.map((item: any, index: number) => (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.8,
                            background: item?.in ? "#fff" : "",
                            py: 2,
                            px: item?.in ? 2 : 1,
                            border: item?.in ? "1px solid #F2F2F2" : "",
                            borderRadius: item?.in ? "10px" : "",
                            ml: item?.in ? 0 : 1,
                        }}
                    >
                        {index === msgList?.length - 1 && loading && (
                            <Typing
                                onClick={() =>
                                    setMsgList((prev: any[]) => {
                                        return prev?.map((message) => {
                                            if (message?.latest) {
                                                message.latest = false;
                                                message.msg = (
                                                    <Typography color={"error"}>Response Terminated</Typography>
                                                );
                                            }
                                            return message;
                                        });
                                    })
                                }
                            />
                        )}
                        <Typography>{item?.msg}</Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{ width: "90%" }}>
                <InputField handleSend={handelSend} />
            </Box>
        </Box>
    );
};

export default MessageBox;
