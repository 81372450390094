import React from "react";

const HistoryIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_339_208"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
            >
                <rect width="22" height="22" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_339_208)">
                <path
                    d="M11 19.25C8.89167 19.25 7.05451 18.551 5.48854 17.1531C3.92257 15.7552 3.025 14.0097 2.79583 11.9167H4.675C4.88889 13.5056 5.59549 14.8194 6.79479 15.8583C7.9941 16.8972 9.39583 17.4167 11 17.4167C12.7875 17.4167 14.3038 16.7941 15.549 15.549C16.7941 14.3038 17.4167 12.7875 17.4167 11C17.4167 9.2125 16.7941 7.69618 15.549 6.45104C14.3038 5.2059 12.7875 4.58333 11 4.58333C9.94583 4.58333 8.96042 4.82778 8.04375 5.31667C7.12708 5.80556 6.35556 6.47778 5.72917 7.33333H8.25V9.16667H2.75V3.66667H4.58333V5.82083C5.3625 4.84306 6.31354 4.08681 7.43646 3.55208C8.55938 3.01736 9.74722 2.75 11 2.75C12.1458 2.75 13.2191 2.96771 14.2198 3.40313C15.2205 3.83854 16.0913 4.42674 16.8323 5.16771C17.5733 5.90868 18.1615 6.77951 18.5969 7.78021C19.0323 8.7809 19.25 9.85417 19.25 11C19.25 12.1458 19.0323 13.2191 18.5969 14.2198C18.1615 15.2205 17.5733 16.0913 16.8323 16.8323C16.0913 17.5733 15.2205 18.1615 14.2198 18.5969C13.2191 19.0323 12.1458 19.25 11 19.25ZM13.5667 14.85L10.0833 11.3667V6.41667H11.9167V10.6333L14.85 13.5667L13.5667 14.85Z"
                    fill="#484848"
                />
            </g>
        </svg>
    );
};

export default HistoryIcon;
