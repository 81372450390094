import React from "react";

const ExcelIcon = () => {
    return (
        <svg width="54" height="55" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M93.7461 9.375H43.7445C42.0873 9.375 40.498 10.0333 39.3262 11.2051C38.1544 12.3769 37.4961 13.9662 37.4961 15.6234V42.1875L93.7461 75L121.871 86.8688L149.996 75V42.1875L93.7461 9.375Z" fill="#21A366"/>
<path d="M37.4961 42.1836H93.7461V74.9961H37.4961V42.1836Z" fill="#107C41"/>
<path d="M143.752 9.375H93.75V42.1875H150V15.6234C150 13.9662 149.342 12.3769 148.17 11.2051C146.998 10.0333 145.409 9.375 143.752 9.375Z" fill="#33C481"/>
<path d="M93.7461 75.0039H37.4961V134.38C37.4961 136.038 38.1544 137.627 39.3262 138.799C40.498 139.971 42.0873 140.629 43.7445 140.629H143.748C145.405 140.629 146.994 139.971 148.166 138.799C149.338 137.627 149.996 136.038 149.996 134.38V107.816L93.7461 75.0039Z" fill="#185C37"/>
<path d="M93.75 75.0039H150V107.816H93.75V75.0039Z" fill="#107C41"/>
<path opacity="0.1" d="M78.1227 32.8086H37.4961V121.871H78.1227C79.7783 121.866 81.3648 121.206 82.5355 120.036C83.7062 118.865 84.3662 117.278 84.3711 115.623V39.057C84.3662 37.4014 83.7062 35.8149 82.5355 34.6442C81.3648 33.4734 79.7783 32.8135 78.1227 32.8086Z" fill="black"/>
<path opacity="0.2" d="M73.4352 37.5H37.4961V126.563H73.4352C75.0908 126.558 76.6773 125.898 77.848 124.727C79.0187 123.556 79.6787 121.97 79.6836 120.314V43.7484C79.6787 42.0928 79.0187 40.5063 77.848 39.3356C76.6773 38.1648 75.0908 37.5049 73.4352 37.5Z" fill="black"/>
<path opacity="0.2" d="M73.4352 37.5H37.4961V117.188H73.4352C75.0908 117.183 76.6773 116.523 77.848 115.352C79.0187 114.181 79.6787 112.595 79.6836 110.939V43.7484C79.6787 42.0928 79.0187 40.5063 77.848 39.3356C76.6773 38.1648 75.0908 37.5049 73.4352 37.5Z" fill="black"/>
<path opacity="0.2" d="M68.7477 37.5H37.4961V117.188H68.7477C70.4033 117.183 71.9898 116.523 73.1605 115.352C74.3312 114.181 74.9911 112.595 74.9961 110.939V43.7484C74.9911 42.0928 74.3312 40.5063 73.1605 39.3356C71.9898 38.1648 70.4033 37.5049 68.7477 37.5Z" fill="black"/>
<path d="M6.24844 37.5H68.7516C70.4088 37.5 71.9981 38.1583 73.1699 39.3301C74.3417 40.5019 75 42.0912 75 43.7484V106.252C75 107.909 74.3417 109.498 73.1699 110.67C71.9981 111.842 70.4088 112.5 68.7516 112.5H6.24844C4.59125 112.5 3.00193 111.842 1.83012 110.67C0.658316 109.498 0 107.909 0 106.252L0 43.7484C0 42.0912 0.658316 40.5019 1.83012 39.3301C3.00193 38.1583 4.59125 37.5 6.24844 37.5Z" fill="#107C41"/>
<path d="M16.5605 98.4414L31.7293 74.9383L17.8355 51.5664H29.0152L36.5996 66.5055C37.2965 67.9211 37.7762 68.9773 38.0387 69.6742H38.1371C38.6371 68.543 39.1606 67.443 39.7074 66.3742L47.8121 51.5664H58.0731L43.8231 74.807L58.434 98.4414H47.5121L38.7512 82.0352C38.3384 81.3363 37.9886 80.6022 37.7059 79.8414H37.5746C37.3189 80.5854 36.9793 81.2978 36.5621 81.9648L27.548 98.4414H16.5605Z" fill="white"/>
</svg>
    );
};

export default ExcelIcon;
