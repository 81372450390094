import { ArrowBackIos } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VarianceSocket from "./utils/VarianceSocket";
import MessageBox from "./MessageBox";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import axios from "axios";

const ChatBox = () => {
    const navigate = useNavigate();
    const [currentMonth, setCurrentMonth] = useState<string>("Feb_23");
    const [previousMonth, setPreviousMonth] = useState<string>("Jan_23");
    const [data, setData] = useState<any[]>([]);
    let data2:any = [];

    const [loading, setLoading] = useState<boolean>(false);
    const [varianceMsg, varianceSocketHooks, varianceMsgRef] = VarianceSocket();
    const [status, setStatus] = useState<string>("Generate Report");
    const [visibleChat, setVisibleChat] = useState<boolean>(false);

    const generateReport = () => {
        setLoading(true);
        axios?.get("https://demo-bc-api.softsensor.ai/bond_llm/get_variance_report")?.then((res: any) => {
            setStatus("Report Fetched");
            if (res?.status === 200) {
                setVisibleChat(true);
                setData(JSON?.parse(res?.data));
            } else {
                setVisibleChat(false);
                setData([]);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (varianceMsgRef?.load === false) {
            setStatus("Fetching Report");
            generateReport();
        }
    }, [varianceMsgRef?.load]);

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <IconButton
                    onClick={() => navigate("/report_generation")}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                    <ArrowBackIos sx={{ fontSize: "1.2rem", pl: 0.7 }} />
                </IconButton>
                <Typography>Back</Typography>
            </Box>
            <Box sx={{ flex: 1, display: "flex", width: "100%" }}>
                {!visibleChat && (
                    <>
                        <Box sx={{ p: 4, display: "flex", flexDirection: "column", gap: 3 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
                                <Typography variant="caption">Current Month</Typography>
                                <Select
                                    sx={{ height: "2.2rem", width: "18rem" }}
                                    value={currentMonth}
                                    onChange={(e) => setCurrentMonth(e?.target?.value)}
                                >
                                    {[
                                        "Jan_23",
                                        "Feb_23",
                                        "Mar_23",
                                        "Apr_23",
                                        "May_23",
                                        "Jun_23",
                                        "Jul_23",
                                        "Aug_23",
                                        "Sep_23",
                                        "Oct_23",
                                        "Nov_23",
                                        "Dec_23",
                                    ]?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                </Select>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
                                <Typography variant="caption">Previous Month</Typography>
                                <Select
                                    sx={{ height: "2.2rem", width: "18rem" }}
                                    value={previousMonth}
                                    onChange={(e) => setCurrentMonth(e?.target?.value)}
                                >
                                    {[
                                        "Jan_23",
                                        "Feb_23",
                                        "Mar_23",
                                        "Apr_23",
                                        "May_23",
                                        "Jun_23",
                                        "Jul_23",
                                        "Aug_23",
                                        "Sep_23",
                                        "Oct_23",
                                        "Nov_23",
                                        "Dec_23",
                                    ]?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                </Select>
                            </Box>
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    height: "2.2rem",
                                    color: "#007BFF",
                                    width: "18rem",
                                    mt: 1,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    setStatus("Generating Report");
                                    varianceSocketHooks?.sendJsonMessage({
                                        variance_report: {
                                            current_month: currentMonth,
                                            previous_month: previousMonth,
                                        },
                                    });
                                    //generateReport();
                                
                                }}
                            >
                                {loading ? (
                                    <CircularProgress style={{ width: "25px", height: "25px" }} />
                                ) : (
                                    "Generate Report"
                                )}
                            </Button>
                        </Box>
                        <Divider orientation="vertical" />
                    </>
                )}
                <ReflexContainer orientation="vertical">
                    {visibleChat && (
                        <ReflexElement flex={1}>
                            <MessageBox varianceSocketHooks={varianceSocketHooks} varianceMsgRef={varianceMsgRef} />
                        </ReflexElement>
                    )}
                    {visibleChat && (
                        <ReflexSplitter>
                            <Box
                                sx={{
                                    width: "16px",
                                    height: "100%",
                                    background: "#E3F4FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    borderWidth: "0px 2px 0px 2px",
                                    borderStyle: "solid",
                                    borderColor: "#E3F4FF",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                            </Box>
                        </ReflexSplitter>
                    )}
                    <ReflexElement minSize={400} size={600} flex={1}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: 1,
                                ml: 2,
                                p: 3,
                            }}
                        >
                            {data?.length > 0 ? (
                                <Box
                                    sx={{
                                        pb: 1,
                                        width: "100%",
                                        overflow: "auto",
                                        height: "80vh",
                                        border: "1px solid #f2f2f2",
                                        borderRadius: "8px",
                                        boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
                                    }}
                                >
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {Object?.keys(data[0])?.map((item: string) => (
                                                    <TableCell
                                                        sx={{
                                                            background: "#e3f4ff",
                                                            minWidth: "10rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {item}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item) => (
                                                <TableRow>
                                                    {Object?.keys(item)?.map((subItem) => (
                                                        <TableCell sx={{ border: "1px solid #f2f2f2" }}>
                                                            {item[subItem]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography sx={{ fontSize: "1.3rem", fontWeight: 600 }}>{status}</Typography>
                                </Box>
                            )}
                        </Box>
                    </ReflexElement>
                </ReflexContainer>
            </Box>
        </Box>
    );
};

export default ChatBox;
