import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import apiService from "../../apis/Api";
import Variance from "../Variance/Variance";


const msalConfig = {
    auth: {
        clientId: "9289d2c7-4e1b-45d0-a18b-bda951def517",
        authority: "https://login.microsoftonline.com/358b0b50-5bbc-4f43-9ae9-84e5db58b77b",
        redirectUri: "/",
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

function AzureAuthentication() {
    const [token, setToken] = useState<any>(null);
    const [resolveResponse, setResolveResponse] = useState<any>(null);
    const [marketPlaceToken, setMarketPlaceToken] = useState<any>(null);

    const getMarketPlaceToken: any = () => {
        const url: any = localStorage.getItem("url");
        const decodedUrl: string = decodeURIComponent(url);
        console.log(decodedUrl);
        const mktPlaceToken = decodedUrl?.substring(decodedUrl?.indexOf("token") + 6, decodedUrl?.length - 1);
        console.log(mktPlaceToken);
        setMarketPlaceToken(mktPlaceToken);
    };

    const logOutLogic: any = () => {
        msalInstance.logoutRedirect();
    };

    const instance = useMsal();
    const account = instance?.instance.getAllAccounts()[0];
    localStorage.setItem("account_user", instance?.accounts[0].username);

    console.log("resolveResponse:", resolveResponse);

    useEffect(() => {
        getMarketPlaceToken();
        console.log(marketPlaceToken);
    }, [window.location.href]);

    // const ResolveObject = JSON.stringify(resolveResponse);

    // localStorage.setItem("resolve_subscription", ResolveObject);

    //activate subscription API
    if (resolveResponse?.id) {
        apiService
            .createClient()
            .post(`/api/saas/subscriptions/${resolveResponse?.id}/activate?api-version=2018-08-31`);
    }

    // add user data to DB

    return (
        <div>
            {/* <AppHeaderBar /> */}
            <Box justifyContent="flex-center" sx={{ padding: 10, paddingLeft: 30 }}>
                <h1 className="text-center">Azure Authentication landing page</h1>

                <Button onClick={logOutLogic} variant="contained">
                    Logout
                </Button>

                <h3 style={{ marginTop: "20px" }}> Some user info</h3>
                <Grid>
                    <Grid>{`Username: ${instance?.accounts[0].username}`}</Grid>
                </Grid>
                <Grid>
                    <Grid>{`Name: ${instance?.accounts[0].name}`}</Grid>
                </Grid>
                {/* <Variance /> */}
            </Box>
        </div>
    );
}

export default AzureAuthentication;
