import { Provider } from "react-redux";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import Routes from "./Route";
import ThemeClient from "./context/theme";
import { Context } from "./context/setting";
import "./App.css";
import { useState } from "react";
import SnackbarAlert from "./screens/Snackbar/Snackbar";
import LoginPage from "./Login";
import Landing_Page from "./Z_AzureMarket";

const { store, persistor } = createStore();

const context = {
    chats: {},
    temparary: {},
};

function App() {
    const [contextStore, setContext] = useState<any>(context);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };
    return (
        <>
            <AuthenticatedTemplate>
                <Provider store={store}>
                    <Context.Provider value={{ contextStore, setContext }}>
                        <PersistGate persistor={persistor}>
                            <ThemeClient>
                                <SnackbarAlert />
                                <Routes />
                            </ThemeClient>
                        </PersistGate>
                    </Context.Provider>
                </Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {/* <Landing_Page /> */}
                <LoginPage />
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;

// import "./App.css";

// import { msalConfig } from "./config";
// import { PublicClientApplication } from "@azure/msal-browser";

// import React , { Component } from "react";

// let isAuth = false;

// class App extends Component {

//     publicClientApplication: any; // You can use the appropriate type instead of 'any'
//     state: Readonly<{}>;
//     constructor(props:any){
//         super(props);
//         this.state = {
//             error: null,
//             isAuthenticate: false,
//             user: {}
//         };
//         this.login = this.login.bind(this)
//         //Initialize the MSAL application object
//         this.publicClientApplication = new PublicClientApplication({
//             auth: {
//                 authority: 'https://login.microsoftonline.com/358b0b50-5bbc-4f43-9ae9-84e5db58b77b',
//                 clientId: '184ded48-19c0-4c86-98db-e1a9ca2de3a8', // Replace with your Azure AD Application's Client ID
//                 redirectUri: 'http://localhost:3000/landing_page', // Replace with your application's redirect URI
//               },
//               cache: {
//                 cacheLocation: "sessionStorage",
//                 storeAuthStateInCookie: true
//               }

//         });
//     }

//     login = async () => {
//         //alert(isAuth);
//         try{
//             //login via popup
//             await this.publicClientApplication.loginPopup({
//                 scopes: [
//                     'user.read'
//                   ],
//                 prompt: "select_account"
//             });
//             console.log('login done');
//             this.setState({isAuthenticated:true})
//             isAuth = true;
//         }
//         catch(err){

//             this.setState({
//                 isAuthenticated: false,
//                 user:{},
//                 error:err
//             });
//             console.log('login failed');
//         }
//     }

//     logout(){
//         this.publicClientApplication.logout();
//     }

//     render(){
//         return (
//             <div className="App">
//                 <header className="App-header">
//                     {isAuth ?
//                      <p>Successfully logged in </p>
//                 :
//                                     <button style={{margin:100}} onClick={()=> this.login()}> Login In</button>}
//                 </header>

//             </div>
//         )
//     }

// }

// export default App;
