import React from "react";

const ChatIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.289 3.23819C6.59146 3.8235 3.79217 6.64134 3.24902 10.333C3.17381 10.8597 3.16128 11.9426 3.22813 12.4819C3.3284 13.3013 3.57073 14.15 3.9384 14.9736L4.13895 15.421L3.66265 17.3065C3.39943 18.3391 3.18635 19.2631 3.18635 19.3592C3.18635 19.5934 3.39943 19.8108 3.62922 19.8108C3.72114 19.8108 4.64867 19.5976 5.69318 19.3383L7.5942 18.8659L7.92844 19.0206C9.67487 19.84 11.7221 20.0365 13.5563 19.5641C16.2177 18.8743 18.3819 16.9219 19.3136 14.3758C19.6521 13.4518 19.815 12.5153 19.815 11.4827C19.815 9.24596 18.9627 7.1723 17.3876 5.60033C16.1676 4.37536 14.6844 3.6061 12.9129 3.27164C12.4115 3.17548 10.8155 3.15458 10.289 3.23819ZM14.3961 9.45918C14.5548 9.56788 14.6342 9.73511 14.6342 9.96087C14.6342 10.2159 14.442 10.4249 14.1746 10.4667C14.0702 10.4793 12.8126 10.4876 11.3753 10.4793C8.97295 10.4667 8.75152 10.4626 8.63035 10.3915C8.38385 10.2577 8.29193 9.91488 8.42981 9.6515C8.50083 9.51771 8.61364 9.44246 8.80583 9.40065C8.87686 9.38811 10.1345 9.37556 11.6051 9.37975C14.1537 9.37975 14.2833 9.38393 14.3961 9.45918ZM12.3238 12.6031C12.7583 12.9209 12.587 13.5354 12.0396 13.6023C11.9185 13.6149 11.1205 13.6232 10.2682 13.6149C8.8309 13.6023 8.71391 13.5981 8.60529 13.5229C8.25015 13.2804 8.30864 12.6867 8.70138 12.5488C8.81001 12.5111 9.29466 12.4986 10.5272 12.507C12.1483 12.5153 12.2109 12.5195 12.3238 12.6031Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default ChatIcon;
