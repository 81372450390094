import React from "react";

const QuestionMarkIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 16.7368C12.2386 16.7368 12.4387 16.6526 12.6004 16.4842C12.7615 16.3158 12.8421 16.1123 12.8421 15.8737C12.8421 15.6351 12.7615 15.4316 12.6004 15.2632C12.4387 15.0947 12.2386 15.0105 12 15.0105C11.7474 15.0105 11.5405 15.0947 11.3794 15.2632C11.2177 15.4316 11.1368 15.6351 11.1368 15.8737C11.1368 16.1123 11.2177 16.3158 11.3794 16.4842C11.5405 16.6526 11.7474 16.7368 12 16.7368ZM11.3895 13.7053H12.5895C12.5895 13.2982 12.6456 12.9718 12.7579 12.7259C12.8702 12.4806 13.1368 12.1474 13.5579 11.7263C13.9368 11.3614 14.2246 11.0209 14.4211 10.7048C14.6175 10.3893 14.7158 10.0211 14.7158 9.6C14.7158 8.87018 14.4561 8.30175 13.9368 7.89474C13.4175 7.48772 12.8 7.28421 12.0842 7.28421C11.3825 7.28421 10.8 7.47368 10.3368 7.85263C9.87368 8.23158 9.54386 8.67368 9.34737 9.17895L10.4421 9.6C10.5404 9.33333 10.7124 9.06667 10.9583 8.8C11.2036 8.53333 11.5719 8.4 12.0632 8.4C12.5684 8.4 12.9404 8.5367 13.1789 8.81011C13.4175 9.08407 13.5368 9.38246 13.5368 9.70526C13.5368 9.98596 13.4526 10.2456 13.2842 10.4842C13.1158 10.7228 12.9053 10.9544 12.6526 11.1789C12.1053 11.6702 11.758 12.0668 11.6109 12.3688C11.4633 12.6703 11.3895 13.1158 11.3895 13.7053ZM12 20C10.8912 20 9.84926 19.7895 8.87411 19.3684C7.89839 18.9474 7.05263 18.3789 6.33684 17.6632C5.62105 16.9474 5.05263 16.1016 4.63158 15.1259C4.21053 14.1507 4 13.1088 4 12C4 10.8912 4.21053 9.84898 4.63158 8.87326C5.05263 7.8981 5.62105 7.05263 6.33684 6.33684C7.05263 5.62105 7.89839 5.05263 8.87411 4.63158C9.84926 4.21053 10.8912 4 12 4C13.1088 4 14.151 4.21053 15.1267 4.63158C16.1019 5.05263 16.9474 5.62105 17.6632 6.33684C18.3789 7.05263 18.9474 7.8981 19.3684 8.87326C19.7895 9.84898 20 10.8912 20 12C20 13.1088 19.7895 14.1507 19.3684 15.1259C18.9474 16.1016 18.3789 16.9474 17.6632 17.6632C16.9474 18.3789 16.1019 18.9474 15.1267 19.3684C14.151 19.7895 13.1088 20 12 20Z"
                fill="#747474"
            />
        </svg>
    );
};

export default QuestionMarkIcon;
