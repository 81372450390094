import React, { useState } from "react";
import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
//import softSensorLogo from "../../assets/softSensorLogo.svg";
import chatIcon from "../../components/Common/svgLogs/ChatIcon";

import DatabaseIcon from "../../components/Common/svgLogs/DatabaseIcon";
import HistoryIcon from "../../components/Common/svgLogs/HistoryIcon";
import settingIcon from "../../assets/settingIcon";
import { useNavigate } from "react-router-dom";
import { Search, AcUnit } from "@mui/icons-material";
import IconButton from "../../components/Common/Components/IconButton";
import SettingIcon from "../../components/Common/svgLogs/SettingIcon";
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import Variance from "../Variance/Variance";
import { CloudUpload } from "@mui/icons-material";



const Index = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");

    const options = [


        { title: "Start Chat", onClick: () => navigate("/landing_page"), icon: chatIcon, tooltip: "start chat" },
        { title: "Variance Chat", onClick: () => navigate("/variance"), icon: CloudUpload, tooltip: "Variance chat" },

        { title: "FinData", onClick: () => navigate("/plan_page"), icon: InfoIcon, Tooltip: "Findata" },

        // { title: "Search", onClick: () => navigate("/search"), icon: Search },
        // { title: "Mongo DB chatbot", onClick: () => navigate("/mongo_DB_chatbot"), icon: AcUnit },
        // { title: "Setting", onClick: () => navigate("/setting"), icon: settingIcon },
        { title: "DataSource", onClick: () => navigate("/data_source/main"), icon: DatabaseIcon },
        { title: "History", onClick: () => navigate("/chat_history/main"), icon: HistoryIcon },
        { title: "FinData Chat", onClick: () => navigate("/"), icon: LogoutIcon },

    ];

    return (
        <Box
            sx={{
                minWidth: "4.2rem",
                background: "#fff",
                // borderRadius: "0px 10px 10px 0px",
                // boxShadow: "2px 0px 11px 0px #aaa",
                boxShadow: "0.8px 0px 16.2px 0px #537CCC1F",
                // marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 3,
                py: "1rem",
                px: 0.2,
                zIndex: 2,
            }}
        >
            {/* <Box>
                <IconButton onClick={() => navigate("/chats")}>
                    <Avatar variant="rounded" src={softSensorLogo}></Avatar>
                </IconButton>
            </Box> */}

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                {options?.map((elem: any) => (
                    <Tooltip title={elem.title} >

                        <IconButton
                            key={elem?.title}
                            style={{
                                height: "2.9rem",
                                width: "2.9rem",
                                background: selected === elem?.title ? "#E3F4FF" : "#fff",
                                boxShadow: "0px 0px 9.4px 0px #E3F4FF8F",
                            }}
                            onClick={() => {
                                setSelected(elem?.title);
                                elem?.onClick();
                            }}
                            variant="rounded"
                        >
                            <elem.icon />
                           

                            {/* <Typography sx={{ color: "#6E7986", fontSize: "0.9rem" }}>{elem?.title}</Typography> */}
                        </IconButton>
                    </Tooltip>

                ))}
            </Box>
            {/* <Box sx={{ py: 0.5, height: "100%", display: "flex", alignItems: "flex-end" }}>
                <Button>
                    <Avatar
                        sx={{
                            border: "4px solid rgba(0, 0, 0, 0.13)",
                            background: "#fff",
                            color: "#2C4860",
                            fontSize: "1rem",
                            fontWeight: 600,
                        }}
                    >
                        PG
                    </Avatar>
                </Button>
            </Box> */}
        </Box>
    );
};

export default Index;
