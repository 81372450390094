import { Add } from "@mui/icons-material";
import { Api } from "../../apis";

import {
    Box,
    CircularProgress,
    Button,
    IconButton,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import PdfIcon from "../../components/Common/svgLogs/PdfIcon";
import WebsiteIcon from "../../components/Common/svgLogs/WebsiteIcon";
import DatabaseTypeIcon from "../../components/Common/svgLogs/DatabaseTypeIcon";
import EditIcon from "../../components/Common/svgLogs/EditIcon";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import moment from "moment";

const dummy = [
    { id: "Documents", title: "Document", type: "PDF", added_by: "User Name", lase_used: "Today", added: "Today" },
    {
        id: "Websites",
        title: "website.com",
        type: "Website",
        added_by: "User Name",
        lase_used: "Today",
        added: "Today",
    },
    {
        id: "Databases",
        title: "Database",
        type: "MongoDB",
        added_by: "User Name",
        lase_used: "Today",
        added: "Today",
    },
];

const DataSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const [tabSelect, setTabSelect] = useState<string>("All");
    const [pdfList, setPdfList] = useState<any[]>(contextStore?.pdfList || []);

    const icons: any = {
        PDF: <PdfIcon />,
        Website: <WebsiteIcon />,
        MongoDB: <DatabaseTypeIcon />,
    };

    const handleFetchPdf = () => {
        Api?.getPdfs()?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pdfList: res?.data?.namespaces?.sort() });
                setPdfList(res?.data?.namespaces?.sort());
            }
            setLoading(false);
        });
    };


    const showConfirmation = (fileName:any) => {
        return window.confirm(`FileName: "${fileName}" ! Are you sure to Delete the Selected Document  ?`);
      };

    const DeletePdf = async (fileName: any) => {
        const apiUrl = 'https://demo-bc-api.softsensor.ai/bond_llm/delete';

        const account_user = localStorage.getItem("account_user");
                    
        let mailId = '';
        if (account_user !== null) {

            const plan_info_json = JSON.parse(account_user);
            mailId = plan_info_json.username;
            
            }
        console.log(fileName + ' :: ' + mailId);
        
        if (showConfirmation(fileName)){
            try {
                const response = await fetch(`${apiUrl}?mail_id=${mailId}&file_name=${fileName}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json', 
                  },
                });

                console.log(response + "::" + response.status); 
                //alert(response.status);

            
                // if (!response.ok) {
                //   throw new Error(`HTTP error! Status: ${response.status}`);
                // }
            
                // If the DELETE request is successful, you can handle the response here
                const data = await response.json();
                console.log('Delete successful', data);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message:
                            "Pdf Deleted Successfully",
                    },
                });
              } catch (error) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message:
                            "Deletion Failed , Please Try Again",
                    },
                });
              }

        }
           
       
      };

   

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Typography variant="h6" fontWeight={600}>
                        Data Source
                    </Typography>
                  
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <OutlinedInput
                        sx={{
                            pr: "1.8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "7.6px",
                                borderColor: "#CFCFCF",
                                overflow: "hidden",
                            },
                            "& .MuiOutlinedInput-input": {
                                height: "0.5rem",
                                padding: "14px 12px",
                            },
                        }}
                        placeholder="Search"
                        endAdornment={
                            <IconButton sx={{ borderRadius: "4px" }}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.91732 15.4245C10.3225 15.4242 11.6872 14.9538 12.7941 14.0881L16.2743 17.5683L17.3937 16.4489L13.9135 12.9687C14.7796 11.8617 15.2503 10.4967 15.2507 9.09115C15.2507 5.5991 12.4094 2.75781 8.91732 2.75781C5.42528 2.75781 2.58398 5.5991 2.58398 9.09115C2.58398 12.5832 5.42528 15.4245 8.91732 15.4245ZM8.91732 4.34115C11.5369 4.34115 13.6673 6.47152 13.6673 9.09115C13.6673 11.7108 11.5369 13.8411 8.91732 13.8411C6.29769 13.8411 4.16732 11.7108 4.16732 9.09115C4.16732 6.47152 6.29769 4.34115 8.91732 4.34115Z"
                                        fill="#484848"
                                    />
                                </svg>
                            </IconButton>
                        }
                    />
                    <Button
                        startIcon={<Add />}
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            px: 2,
                            borderRadius: "7.31px",
                            "&:hover": { background: "#007BFFc3" },
                        }}
                        onClick={() => navigate("/data_source/add_source")}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {["All", "Documents"]?.map((elem) => (
                    <Button
                        sx={{
                            textTransform: "none",
                            background: tabSelect === elem ? "#E3F4FF" : "inherit",
                            px: "2rem",
                            borderRadius: "10px",
                        }}
                        onClick={() => setTabSelect(elem)}
                    >
                        {elem}
                    </Button>
                ))}
                  <Button
                            sx={{ textTransform: "none", height: "2.3rem" }}
                            onClick={() => {
                                setLoading(true);
                                handleFetchPdf();
                            }}
                        >
                            {loading ? (
                                <CircularProgress color="inherit" style={{ width: "12px", height: "12px" }} />
                            ) : (
                                "refresh"
                            )} 
                        </Button>
            </Box>
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            {["Title", "Type", "Added By", "Last Used", "Added On", "Action"]?.map((elem) => (
                                <TableCell
                                    sx={{ borderBottomWidth: "2px", pl: 3, width: elem === "" ? "5rem" : "auto" }}
                                >
                                    <Typography variant="caption">{elem}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pdfList?.map((item: string) => (
                            <TableRow>
                                {/* {["title", "type", "added_by", "lase_used", "added", ""]?.map((elem: string) =>
                                    elem === "" ? (
                                        <TableCell>
                                            <EditIcon />
                                            <DeleteIcon />
                                        </TableCell>
                                    ) : (
                                        <TableCell sx={{ pl: 3 }}>
                                            {elem === "type" ? (
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    {icons[item.type]} {item[elem]}
                                                </Box>
                                            ) : (
                                                item[elem]
                                            )}
                                        </TableCell>
                                    )
                                )} */}
                                <TableCell sx={{ pl: 3 }}>{item}</TableCell>
                                <TableCell sx={{ pl: 3 }}>{icons["PDF"]} Document</TableCell>
                                <TableCell sx={{ pl: 3 }}>
                                    {contextStore?.chats[contextStore?.selected]?.userName}
                                </TableCell>
                                <TableCell sx={{ pl: 3 }}>{moment(new Date())?.format("dddd")}</TableCell>
                                <TableCell sx={{ pl: 3 }}>{moment(new Date())?.format("dddd")}</TableCell>
                                <TableCell>
                                    {/* <EditIcon /> */}
                                    <Button onClick={() => DeletePdf(item)}> <DeleteIcon color={"#CC5555"} /></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {pdfList?.length === 0 && (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 8 }}>
                        <Typography variant="caption" sx={{ fontSize: "1rem" }}>
                            Data sources not uploaded yet, Please add new one.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DataSource;

 // useEffect(() => {
    //     const plan_info_str = localStorage.getItem("plan_info");
    //     const plan_info = localStorage.getItem("account_user");
    //     let plan_name = '';
    //     if (plan_info !== null) {
            
    //         const plan_info_json = JSON.parse(plan_info);
    //         plan_name = plan_info_json.plan_id;
    //         console.log(plan_name);
    //          }
        
    
    //     if (plan_info_str !== null) {
    //         let plan_info = JSON.parse(plan_info_str);
    
    //         const filteredPlanInfo = plan_info.filter((item: any) => item.planId === plan_name);
    
    //         // Convert the filtered array back to a JSON string
    //         const filteredPlanInfoStr = JSON.stringify(filteredPlanInfo[0]);
    
    //         // Save the filtered array back to localStorage
    //         localStorage.setItem("plan_info", filteredPlanInfoStr);
    //     }

    // }, []);


    // useEffect(() => {
    //     if (tabSelect === "All") {
    //         setDummyTitle(dummy);
    //     } else {
    //         setDummyTitle(dummy?.filter((item) => item?.id === tabSelect));
    //     }
    // }, [tabSelect]);