import {
    Box,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../context/setting";


const EnterSettings = ({ userSettings }: { userSettings: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [parsona, setParsona] = useState<string>(contextStore.temparary?.parsona || "");
    const [model, setModel] = useState<string>(contextStore.temparary?.model || "gpt-3.5-turbo");
    const [tempreture, setTempreture] = useState<number>(contextStore.temparary?.tempreture || 0);
    const [prompts, setPrompts] = useState<string>(contextStore.temparary?.prompts || "");
    const [mail_id, setMail_id] = useState<string>(contextStore.temparary?.mail_id || "mukund@gmail.com");
    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, width: "30rem", marginLeft: 15, marginTop: 4 }}>
               <Box sx={{display: "flex", flexDirection: "column", gap: 0.5 , width:"35rem", border:"1px solid grey" , borderRadius:5 , padding:3}}>
                <Box>
                    <Typography variant="h6">LLM Model</Typography>
                    <Typography variant="caption">Model</Typography>
                    <RadioGroup
                        value={model}
                        row
                        sx={{ gap: 1 }}
                        onChange={(e: any) => {
                            setModel(e?.target?.value);
                            userSettings.model = e?.target?.value;
                        }}
                    >
                        <FormControlLabel
                            value={"gpt-3.5-turbo"}
                            control={<Radio size="small" />}
                            label={"gpt-3.5-turbo"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        />
                        {/* <FormControlLabel
                            value={"gpt-3.5-finetuned"}
                            control={<Radio size="small" />}
                            label={"gpt-3.5-finetuned"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        /> */}
                        {/* <FormControlLabel
                            value={"llama2"}
                            control={<Radio size="small" />}
                            label={"llama2"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        /> */}
                    </RadioGroup>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="caption">Tempreture</Typography>
                        <Box
                            sx={{
                                border: "1px solid #CFCFCF",
                                px: 1,
                                py: 0.5,
                                color: "#007BFF",
                                borderRadius: "4px",
                            }}
                        >
                            {tempreture / 100}
                        </Box>
                    </Box>
                    <Box sx={{ px: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pt: 1.5 }}>
                            <Typography variant="caption" color={"#007BFF"}>
                                0.00
                            </Typography>
                            <Typography variant="caption" color={"#007BFF"}>
                                1.00
                            </Typography>
                        </Box>
                        <Slider
                            value={tempreture}
                            onChange={(e: any, value: number | number[]) => {
                                setTempreture(value as number);
                                userSettings.tempreture = e?.target?.value;
                            }}
                        ></Slider>
                    </Box>
                </Box>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", gap: 0.5 , width:"35rem", border:"1px solid grey" , borderRadius:5 , padding:3}}>
                    <Box>
                        <Box>
                        <Typography variant="h6">Prompts</Typography>
                            <Typography variant="caption">Choose Persona </Typography>
                        </Box>
                        <Select
                            displayEmpty
                            fullWidth
                            size="small"
                            value={parsona}
                            sx={{
                                borderColor: "#CFCFCF",
                                mt: 1,
                                color: parsona === "" ? "#6B6B6B" : "inherit",
                            }}
                            onChange={(e: { target: { value: string } }) => {
                                setParsona(e?.target?.value);
                                userSettings.parsona = e?.target?.value;
                            }}
                        >
                            <MenuItem disabled value="">
                                Select Persona
                            </MenuItem>
                            <MenuItem value="1">Financial Analyst</MenuItem>
                            {/* <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem> */}
                        </Select>
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant="caption">Custom Prompts (Upcoming)</Typography>
                        </Box>
                        <Select
                            displayEmpty
                            fullWidth
                            size="small"
                            value={prompts}
                            sx={{
                                borderColor: "#CFCFCF",
                                mt: 1,
                                color: prompts === "" ? "#6B6B6B" : "inherit",
                            }}
                            onChange={(e: { target: { value: string } }) => {
                                setPrompts(e?.target?.value);
                                userSettings.prompts = [e?.target?.value];
                            }}
                        >
                            <MenuItem disabled value="">
                                Select Prompts
                            </MenuItem>
                            <MenuItem value="1">FA : Analyze Provider </MenuItem>
                            {/* <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem> */}
                        </Select>
                    </Box>
                    
                    
                </Box>




            </Box>
        </Box>
    );
};

export default EnterSettings;
