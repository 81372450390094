import React from "react";

const LessThanIcon = () => {
    return (
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 13.5L1 7.5L7 1.5"
                stroke="#25282B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default LessThanIcon;
