import { Box, Link, Typography, Checkbox, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchMidiumIcon from "../../components/Common/svgLogs/SearchMidiumIcon";
import { Api } from "../../apis";
import { Context } from "../../context/setting";

const EnterSource = ({ userSources }: { userSources: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [tabSelect, setTabSelect] = useState<string>("All Documents");
    const [selectedPdf, setSelectedPdf] = useState<string>(contextStore.temparary?.source || "");

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, px: 4, mt: 4 , width:"35rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h6">Select Data Source</Typography>
                <SearchMidiumIcon />
            </Box>
            <Box
                sx={{
                    border: "1px solid #CFCFCF",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    height: "65vh",
                    py: 1,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                    {["All Documents"]?.map((elem) => (
                        <Link
                            sx={{
                                textDecoration: tabSelect === elem ? "underline" : "none",
                                color: tabSelect === elem ? "#007BFF" : "#7F8082",
                            }}
                        >
                            {elem}
                        </Link>
                    ))}
                </Box>
                <Box sx={{ flex: 1, overflowY: "auto", mt: 2, px: 2 }}>
                    {contextStore?.pdfList?.map((elem: string) => (
                        <Box
                            sx={{ display: "flex", alignItems: "center", userSelect: "none", cursor: "pointer" }}
                            onClick={() => {
                                if (selectedPdf === elem) {
                                    setSelectedPdf("");
                                    userSources.source = "";
                                } else {
                                    setSelectedPdf(elem);
                                    userSources.source = elem;
                                }
                            }}
                        >
                            <Checkbox checked={selectedPdf === elem} 
                             onClick={() => {
                                if (selectedPdf === elem) {
                                    setSelectedPdf("");
                                    
                                    localStorage.setItem("selected_pdf", selectedPdf);
                                    //alert(selectedPdf);
                                    userSources.source = "";
                                } else {
                                    setSelectedPdf(elem);
                                    
                                    localStorage.setItem("selected_pdf", selectedPdf);
                                    //alert(selectedPdf);
                                    userSources.source = elem;
                                }
                            }}/>
                            <Typography variant="subtitle2">{elem}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default EnterSource;
