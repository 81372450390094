import React, { useContext, useEffect, useState } from "react";
//import { Context } from "../../../context/setting";

import { OutlinedInput, Box, IconButton } from "@mui/material";
//import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";
import { Context } from "../../context/setting";
import { Send } from "@mui/icons-material";

const InputField = ({ handleSend }: { handleSend: (value: string) => void }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    handleSend(e?.target?.value);
                }
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {/* {contextStore?.activeSource === "excel" && (
                        <IconButton onClick={() => handleSend(query, "sql")}>
                            <ESGSearchIcon />
                        </IconButton>
                    )} */}
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => handleSend(query)}
                    >
                        <Send />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputField;
