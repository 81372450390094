import React, { useContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { Context } from "../../context/setting";

const SnackbarAlert = () => {
    const { contextStore, setContext } = useContext(Context);
    const [open, setOpen] = useState(true);
    useEffect(() => {
        setOpen(contextStore?.snackState?.open);
    }, [contextStore?.snackState?.open]);
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() =>
                setContext((prev: any) => ({ ...prev, snackState: { open: false, errorType: "", message: "" } }))
            }
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert
                severity={contextStore?.snackState?.errorType === "" ? "success" : contextStore?.snackState?.errorType}
            >
                {contextStore?.snackState?.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarAlert;
