import React from "react";

const FinData = () => {
    return (
        <svg width="92"  height="20" style={{marginBottom:7 }} viewBox="0 0 92 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.2424 0.708664V3.67931H3.43475V8.3698H11.7995V11.4577H3.39566V19.3403H0.00808172V0.708664H12.2424ZM18.301 0.708664V3.44478H14.8483V0.708664H18.301ZM18.301 5.65974V19.3403H14.8483V5.65974H18.301ZM21.5713 19.3403V5.65974H24.2423L24.6722 7.66622C25.9838 6.32857 27.4778 5.65974 29.1543 5.65974C32.3942 5.65974 34.0141 7.34484 34.0141 10.715V19.3403H30.5614V10.715C30.5614 9.20366 29.8101 8.44797 28.3074 8.44797C27.1869 8.44797 26.0924 8.89531 25.024 9.78997V19.3403H21.5713Z" fill="#093548"/>
<path d="M44.6459 17.386C49.2061 17.386 51.4862 14.7628 51.4862 9.51636C51.4862 4.94748 49.2061 2.66303 44.6459 2.66303H40.2811V17.386H44.6459ZM37.8708 19.3403V0.708664H44.6459C50.813 0.708664 53.8966 3.64456 53.8966 9.51636C53.8966 16.0657 50.813 19.3403 44.6459 19.3403H37.8708ZM56.5024 15.2101C56.5024 12.4045 58.3526 11.0017 62.0528 11.0017C63.2428 11.0017 64.4328 11.0885 65.6228 11.2623V9.97238C65.6228 8.4002 64.3937 7.61411 61.9356 7.61411C60.5284 7.61411 59.0865 7.83126 57.6099 8.26556V6.31119C59.0865 5.87689 60.5284 5.65974 61.9356 5.65974C66.0007 5.65974 68.0332 7.07557 68.0332 9.90724V19.3403H66.6782L65.8443 17.9853C64.4806 18.8887 62.9953 19.3403 61.3884 19.3403C58.1311 19.3403 56.5024 17.9636 56.5024 15.2101ZM62.0528 12.9561C59.9595 12.9561 58.9128 13.6944 58.9128 15.171C58.9128 16.6476 59.738 17.386 61.3884 17.386C63.0561 17.386 64.4676 16.943 65.6228 16.057V13.2166C64.4328 13.0429 63.2428 12.9561 62.0528 12.9561ZM71.955 3.44478H73.4664L73.9875 5.65974H77.6878V7.61411H74.3654V15.4707C74.3654 16.7475 74.9169 17.386 76.0201 17.386H77.6878V19.3403H75.3947C73.1015 19.3403 71.955 18.1112 71.955 15.6531V3.44478ZM80.1112 15.2101C80.1112 12.4045 81.9614 11.0017 85.6616 11.0017C86.8516 11.0017 88.0416 11.0885 89.2316 11.2623V9.97238C89.2316 8.4002 88.0025 7.61411 85.5444 7.61411C84.1372 7.61411 82.6953 7.83126 81.2187 8.26556V6.31119C82.6953 5.87689 84.1372 5.65974 85.5444 5.65974C89.6095 5.65974 91.642 7.07557 91.642 9.90724V19.3403H90.287L89.4531 17.9853C88.0894 18.8887 86.6041 19.3403 84.9972 19.3403C81.7399 19.3403 80.1112 17.9636 80.1112 15.2101ZM85.6616 12.9561C83.5683 12.9561 82.5216 13.6944 82.5216 15.171C82.5216 16.6476 83.3468 17.386 84.9972 17.386C86.6649 17.386 88.0764 16.943 89.2316 16.057V13.2166C88.0416 13.0429 86.8516 12.9561 85.6616 12.9561Z" fill="url(#paint0_linear_1155_1537)"/>
<defs>
<linearGradient id="paint0_linear_1155_1537" x1="36.745" y1="1.67515" x2="93.1143" y2="22.6885" gradientUnits="userSpaceOnUse">
<stop stop-color="#2085B2"/>
<stop offset="1" stop-color="#165CA4"/>
</linearGradient>
</defs>
</svg>

    );
};

export default FinData;
