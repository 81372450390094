import React, { FC, Suspense, useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Fallback from "./components/Fallback";

import DataSource from "./screens/Data_Sources";
import AddSource from "./screens/Data_Sources/AddSource";
import DetailConfirrm from "./screens/Data_Sources/DetailConfirrm";
import ChatHistory from "./screens/ChatHistory";
import NewChat from "./screens/ChatHistory/NewChat";
import { Api } from "./apis";
import { Context } from "./context/setting";
import AzureAuthentication from "./screens/Login_page/azure_login";
import LoginPage from "./Login";
import LandingPage from "./Z_AzureMarket";
import AppLayout from "./AppLayout";
import Plan_info from "./Z_AzureMarket/plan_info";
import ChatBox from "./screens/Variance/ChatBox";
import Variance from "./screens/Variance/Variance";


const Bond_LLM = React.lazy(() => import("./screens/Bond_LLM"));
const Landing = React.lazy(() => import("./screens/Landing_page"));

const Routess: FC = ({ auth }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);

    const handleFetchPDF = () => {
        Api?.getPdfs()?.then((res: any) => {
            setContext({ ...contextStore, pdfList: res?.data?.namespaces?.sort() });
            // let temp: any = {};
            // Object?.keys(contextStore)?.forEach((item: any) => {
            //     if (contextStore[item]?.changed && item !== "fileAcces") {
            //         temp[item] = contextStore[item]?.value;
            //     }
            // });
            // sendJsonMessage({
            //     pdf_name: res?.data?.namespaces?.includes("bank_of_baroda")
            //         ? "bank_of_baroda"
            //         : res?.data?.namespaces?.sort()[0],
            //     setting: { model: selectedEngin, ...temp },
            // });
        });
    };

    useEffect(() => {
        handleFetchPDF();
    }, []);

    return (
        <Router>
            <Routes>
                {Object?.keys(contextStore?.chats)?.length > 0 && (
                    <Route
                        key={"/bond_llm"}
                        path={"/bond_llm"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <Bond_LLM />
                            </Suspense>
                        }
                    />
                )}

                <Route
                    path="index"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <AzureAuthentication />
                        </Suspense>
                    }
                />
                <Route element={<AppLayout />}>
                    <Route
                        key={"azure_auth"}
                        path={"/azure_auth"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <AzureAuthentication />
                            </Suspense>
                        }
                    />
                    <Route
                        path="/get_started"
                        element={
                            <Suspense fallback={<Fallback />}>
                                <LandingPage />
                            </Suspense>
                        }
                    />
                    <Route
                        key={"Landing_page"}
                        path={"/landing_page"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <Landing />
                            </Suspense>
                        }
                    />
                    <Route
                        key={"plan_page"}
                        path={"/plan_page"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <Plan_info />
                            </Suspense>
                        }
                    />
                    <Route
                        key={"variance_generation"}
                        path={"/variance_generation"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <ChatBox />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        key={"variance"}
                        path={"/variance"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <Variance />
                            </Suspense>
                        }
                    ></Route>
                    <Route key={"data_source"} path={"/data_source"}>
                        <Route
                            key={"main"}
                            path={"main"}
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <DataSource />
                                </Suspense>
                            }
                        />

                        <Route
                            key={"add_source"}
                            path={"add_source"}
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <AddSource />
                                </Suspense>
                            }
                        />
                        <Route
                            key={"add_detail"}
                            path={"add_detail"}
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <DetailConfirrm />
                                </Suspense>
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <LandingPage />
                                </Suspense>
                            }
                        />
                    </Route>
                    <Route key={"chat_history"} path={"/chat_history"}>
                        <Route
                            key={"main"}
                            path={"main"}
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <ChatHistory />
                                </Suspense>
                            }
                        />
                        <Route key={"new_chat"} path={"new_chat"} element={<NewChat backRoute={true} />} />
                    </Route>
                    <Route
                        key={"new_chat"}
                        path={"/new_chat"}
                        element={
                            <Suspense fallback={<Fallback />}>
                                <NewChat />
                            </Suspense>
                        }
                    />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<Fallback />}>
                            <LandingPage />
                        </Suspense>
                        // <Suspense fallback={<Fallback />}>
                        //     <Login_page />
                        // </Suspense>
                    }
                />
            </Routes>
        </Router>
    );
};

export default Routess;
