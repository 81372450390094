import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { Add } from "@mui/icons-material";
//import SonftSensorMiniXIcon from "../../components/Common/svgLogs/SonftSensorMiniXIcon";
import { Navigate, useNavigate } from "react-router-dom";
import FinData from "../assets/FinData";
import LandinglogoIcon from "../components/Common/svgLogs/LandinglogoIcon";
import FinDataLogo from "../assets/FinDataLogo";
import { Context } from "../context/setting";

import Homebackground from "./homescreen.png";
import { Grid } from "@mantine/core";
import { useMsal } from "@azure/msal-react";

const LandingPage = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "110%",
                height: "580px",
                marginTop: -3,
                marginLeft: -3,
                marginRight: 0,
                marginBottom: 0,
                //gap: 3,
                backgroundImage: `url(${Homebackground})`,
                backgroundSize: "100% 100%",
                backgroundPosition: "Top",
                backgroundRepeat: "no-repeat",
            }}
        >
            <Grid sx={{ marginTop: -250, marginLeft: -100 }}>
                <Grid.Col span={2}>
                    {/* <Button
                        sx={{
                            borderRadius: "10px",
                            width: "180px",
                            border: "1px solid #C4C7C9",
                            background: "#1B75BC",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#3D3232",

                                fontSize: "14px",

                                fontWeight: 400,
                            }}
                        >
                            Book a Demo
                        </Typography>
                    </Button> */}
                </Grid.Col>
                <Grid.Col span={6}>
                    <Button
                        sx={{
                            borderRadius: "10px",
                            width: "180px",
                            border: "1px solid #C4C7C9",
                            background: "#FFF",
                        }}
                        // onClick={() => setContext({
                        //     ...contextStore,
                        //     snackState: {
                        //         open: true,
                        //         errorType: "info",
                        //         message: "First Activate Subscription",
                        //     },
                        // })}
                        
                        onClick={() => navigate("/landing_page")}
                    >
                        <Typography
                            sx={{
                                color: "#3D3232",

                                fontSize: "14px",

                                fontWeight: 400,
                            }}
                        >
                            Get Started
                        </Typography>
                    </Button>
                </Grid.Col>
            </Grid>
        </Box>
    );
};

export default LandingPage;
