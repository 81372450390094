import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    LinearProgress,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import { useNavigate } from "react-router-dom";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import { Context } from "../../context/setting";
import { Api } from "../../apis";
import { useMsal } from "@azure/msal-react";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';




const DetailConfirrm = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [pdfList, setPdfList] = useState<string[]>(contextStore?.pdfList || []);
    const instance = useMsal();
    const account = instance?.instance.getAllAccounts()[0];
    // localStorage.setItem("account_user", instance?.accounts[0].username );
    useEffect(() => {
        //localStorage.setItem("account_user", instance?.accounts[0].username );
        //alert(instance?.accounts[0].username);
        if (contextStore?.source_file) {
            handleUpload(contextStore?.source_file);
        }
    }, []);
    // useEffect(() => {
    //     const plan_info_str = localStorage.getItem("plan_info");
    //     const plan_info = localStorage.getItem("account_user");
    //     let plan_name = '';
    //     if (plan_info !== null) {
            
    //         const plan_info_json = JSON.parse(plan_info);
    //         plan_name = plan_info_json.plan_id;
    //         console.log(plan_name);
    //          }

              
        
    
    //     if (plan_info_str !== null) {
    //         let plan_info = JSON.parse(plan_info_str);

    //         if (Array.isArray(plan_info)) {
    //             // Filter the array
    //             const filteredPlanInfo = plan_info.filter((item:any) => item.planId === plan_name);
    //             console.log("Filtered Plan Info:", filteredPlanInfo);
        
    //             // Convert the filtered array back to a JSON string
    //             const filteredPlanInfoStr = JSON.stringify(filteredPlanInfo[0]);
    //             console.log("Filtered Plan Info String:", filteredPlanInfoStr);
        
    //             // Save the filtered array back to localStorage
    //             localStorage.setItem("plan_info", filteredPlanInfoStr);
    //         } else {
    //             alert("Parsed JSON is not an array.");
    //         }   
    
    //         //const filteredPlanInfo = plan_info.filter((item: any) => item.planId === plan_name);
    
           
    //     }else {
    //         alert("plan info having null value");
    //     }

    // }, []);
  
    const handleUpload = (file: any) => {
        const plan_info = localStorage.getItem("plan_info");

        //alert("1"+ plan_info);

        if (plan_info !== null) {
            
            const pages_info = JSON.parse(plan_info);

            // setContext({
            //     ...contextStore,
            //     snackState: {
            //         open: true,
            //         errorType: "info",
            //         message: `Plan's Page count Remaining : ${pages_info.numberOfPages-pages_info.pagesQuotaConsumed}`,
            //     },
            // });

            // setTimeout(() => {
            //     setContext({
            //         ...contextStore,
            //         snackState: {
            //             open: true,
            //             errorType: "info",
            //             message: `Balance No of pages : ${pages_info.numberOfPages-pages_info.pagesQuotaConsumed}`,
            //         },
            //     });
            // }, 6000); // 10000 milliseconds = 10 seconds

            //alert(pages_info.pagesQuotaConsumed +" :: :: "+ pages_info.numberOfPages)
            const balance = pages_info.numberOfTokens-pages_info.tokenQuotaConsumed;
            console.log(pages_info.pagesQuotaConsumed + "::::" + pages_info.numberOfPages);

            let token_status = false;
            //alert(balance);
            if(balance>0){
                  token_status = true;
                  //alert(token_status);
                  //alert(pages_info.numberOfTokens-pages_info.tokenQuotaConsumed + ":: balance Token");
            }
            if ( token_status ) {
              // Do something with the data
             // console.log();
               // alert(instance?.accounts[0].username);
        const formData = new FormData();
        
        // formData.append("mail_id", "as"  );
        // formData.append("plan_id", "plan2-testing" );
        formData.append("pdf_file", file );
        //alert(formData);
        console.log(formData);
        Api?.uploadPdf(formData)?.then((res: any) => {
            if (res?.status === 202) {
                handleFetchPdf();
                setContext({
                    ...contextStore,
                    source_file: undefined,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "PDF Uploaded Successful",
                    },
                });
            }
        });
            } else {
              // Handle the case where the data is not in the expected format
             // console.error("Error parsing data from local storage");
              setContext({
                ...contextStore,
                source_file: undefined,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "PDF Uploading Failed , Your PDF pages count consumed !",
                },
            });
            }
          } else {
            // Handle the case where the data is not found in local storage
           // console.error("Data not found in local storage");
          }
       
      
    };

    const handleFetchPdf = () => {
        Api?.getPdfs()?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pdfList: res?.data?.namespaces?.sort() });
                setPdfList(res?.data?.namespaces?.sort());
            }
            setLoading(false);
        });
    };
    const showConfirmation = (fileName:any) => {
        return window.confirm(`FileName: "${fileName}" ! Are you sure to Delete the Selected Document  ?`);
      };

    const DeletePdf = async (fileName: any) => {
        const apiUrl = 'https://demo-bc-api.softsensor.ai/bond_llm/delete';

        const account_user = localStorage.getItem("account_user");
                    
        let mailId = '';
        if (account_user !== null) {

            const plan_info_json = JSON.parse(account_user);
            mailId = plan_info_json.username;
            
            }
        console.log(fileName + ' :: ' + mailId);
        
        if (showConfirmation(fileName)){
            try {
                const response = await fetch(`${apiUrl}?mail_id=${mailId}&file_name=${fileName}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json', 
                  },
                });

                console.log(response+'::'+response.status); 
                //alert(response.status);

            
                // if (!response.ok) {
                //   throw new Error(`HTTP error! Status: ${response.status}`);
                // }
            
                // If the DELETE request is successful, you can handle the response here
                const data = await response.json();
                console.log('Delete successful', data);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message:
                            "Pdf Deleted Successfully",
                    },
                });
              } catch (error) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message:
                            "Deletion Failed , Please Try Again",
                    },
                });
              }

        }
           
       
      };

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={() => navigate("/data_source/add_source")}>
                    <LessThanIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600}>
                    Add Data Source
                </Typography>
            </Box>
            <Box sx={{ display: "flex", px: 1.5, gap: 8, height: "100%" }}>
                <Box sx={{ width: "58%" }}>
                    <Typography variant="subtitle2">Upload Documents</Typography>
                    <Box
                        sx={{
                            height: "6rem",
                            border: "1px dashed #007BFF",
                            borderRadius: "14px",
                            mt: 3,
                            display: "flex",
                            alignItems: "center",
                            pl: 5,
                            cursor: "pointer",
                        }}
                        component={"label"}
                    >
                        <input
                            hidden
                            type="file"
                            accept=".pdf, .xls, .xlsx"
                            onChange={(e: { target: { files: any } }) => {
                                // e?.target?.files[0] && navigate("/data_source/add_detail")
                                handleUpload(e?.target?.files[0]);
                            }}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                            <UploadBoxIcon width={38} height={42} />
                            <Box>
                                <Typography variant="subtitle2">
                                    Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                </Typography>
                                <Typography variant="caption">Supported .pdf .txt. csv. json. docx or xlsx</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 3 }}>
                        <Typography variant="subtitle2">Uploaded Documents</Typography>
                        <Button
                            sx={{ textTransform: "none", height: "2.3rem" }}
                            onClick={() => {
                                setLoading(true);
                                handleFetchPdf();
                            }}
                        >
                            {loading ? (
                                <CircularProgress color="inherit" style={{ width: "12px", height: "12px" }} />
                            ) : (
                                "refresh"
                            )} 
                        </Button>
                    </Box>
                    {/* <Box
                        sx={{ px: 1, py: 1.5, display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    >
                        <Typography variant="caption">Document.pdf</Typography>
                        <Typography variant="caption" color={"#CC5555"}>
                            Cancel
                        </Typography>
                    </Box> */}
                    {/* <Box sx={{ px: 1 }}>
                        <LinearProgress variant="determinate" value={20} sx={{ borderRadius: "13px" }} />
                    </Box> */}
                    {/* <Box sx={{ px: 1, py: 1.5 }}>
                        <Divider sx={{ borderColor: "#F3F3F4" }} />
                    </Box> */}
                    {pdfList?.map((item: string) => (
                        <>
                            <Box
                                
                                sx={{
                                    px: 1,
                                    py: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }} 
                               
                            >
                                <Typography variant="caption">{item}</Typography>
                                <Button onClick={() => DeletePdf(item)}> <DeleteIcon color={"#CC5555"} /></Button>
                               
                            </Box>
                            <Box sx={{ px: 1, py: 0.5 }}>
                                <Divider sx={{ borderColor: "#F3F3F4" }} />
                            </Box>
                        </>
                    ))}
                </Box>
                <Box
                    sx={{
                        width: "35%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Typography variant="subtitle2">Dataset Name</Typography>
                        <OutlinedInput
                            sx={{
                                width: "100%",
                                mt: 3,
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                    borderColor: "#CFCFCF",
                                    overflow: "hidden",
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "0.5rem",
                                    // padding: "14px 12px",
                                    fontSize: "0.9rem",
                                    color: "#C7C7C7",
                                },
                            }}
                            placeholder="Enter Dataset name"
                        />
                        <Typography variant="subtitle2" sx={{ mt: 3 }}>
                            Description (Optional)
                        </Typography>
                        <OutlinedInput
                            multiline
                            rows={4}
                            sx={{
                                width: "100%",
                                mt: 3,
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "10px",
                                    borderColor: "#CFCFCF",
                                    overflow: "hidden",
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "0.5rem",
                                    // padding: "14px 12px",
                                    fontSize: "0.9rem",
                                    color: "#C7C7C7",
                                },
                            }}
                        />
                    </Box>
                    <Button
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            "&:hover": { background: "#007BFFa3" },
                            width: "12rem",
                            alignSelf: "flex-end",
                        }}
                        onClick={() => navigate("/bond_llm")}
                    >
                        Save Data Source
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DetailConfirrm;
