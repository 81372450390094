import React from "react";

const XpdfIcon = () => {
    return (
        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.3815 0.710938H42.3668L53.1088 11.9118V54.4508H13.0117V54.5837H53.2417V12.0447L42.3815 0.710938Z" fill="#949494"/>
<path d="M42.235 0.578125H12.8789V54.4509H53.11V11.9119L42.235 0.578125Z" fill="#F1F1F1"/>
<path d="M12.5831 3.46289H0.257812V16.632H12.5831H39.0238V3.46289H12.5831Z" fill="#808080"/>
<path d="M39.2295 3.24121H0.507812V16.4241H39.2295V3.24121Z" fill="#DC1D00"/>
<path d="M12.9825 9.88476C12.9234 9.88476 12.8643 9.88476 12.8052 9.88476V7.02942H13.1755C13.6048 7.02942 13.8706 7.16233 14.0636 7.38489C14.2999 7.6507 14.4191 8.1243 14.4191 8.465C14.4191 8.93861 14.4191 9.36791 13.9898 9.67802C13.7662 9.84046 13.3959 9.88476 12.9825 9.88476ZM13.3516 5.46094C13.3369 5.46094 13.3073 5.46094 13.2926 5.46094C13.1006 5.46094 12.9666 5.46094 12.8928 5.46094H10.8359V14.7231H12.819V11.6009L13.263 11.6305C13.7219 11.6305 14.1364 11.5271 14.5056 11.4089C14.8758 11.2908 15.1859 11.083 15.4528 10.8467C15.7186 10.6105 15.9707 10.3141 16.1036 9.97337C16.3103 9.45546 16.3546 8.73081 16.3103 8.22767C16.2513 7.72453 16.2365 7.31 16.0888 6.98512C15.9412 6.65919 15.7481 6.39338 15.5266 6.18664C15.2904 5.9799 15.0383 5.83117 14.7724 5.7278C14.4908 5.62443 14.2398 5.56536 13.974 5.52106C13.7366 5.4757 13.5299 5.46094 13.3516 5.46094Z" fill="#4D4D4D"/>
<path d="M19.7141 13.0366C19.655 13.0366 19.5959 13.0366 19.5221 13.0366V7.13291C19.5369 7.13291 19.5369 7.13291 19.5516 7.13291C19.9514 7.13291 20.4545 7.13291 20.7056 7.33965C20.9714 7.54639 21.1792 7.81326 21.3268 8.1086C21.4745 8.41977 21.5779 8.75941 21.5927 9.12965C21.6074 9.55895 21.5927 9.89859 21.5927 10.195C21.5927 10.4766 21.5927 10.831 21.5336 11.1865C21.4745 11.542 21.3564 11.8521 21.2077 12.1632C21.0452 12.4596 20.7636 12.6664 20.5125 12.8584C20.291 12.9923 20.0252 13.0366 19.7141 13.0366ZM20.0695 5.44629C19.8628 5.44629 19.6402 5.46106 19.5073 5.46106C19.2563 5.47582 19.1075 5.47582 19.0485 5.47582H17.5391V14.738H19.3143C20.098 14.738 20.7499 14.6199 21.2973 14.3973C21.8448 14.1758 22.2741 13.8499 22.6137 13.4501C22.9396 13.0503 23.1907 12.562 23.3383 11.9998C23.486 11.4375 23.5599 10.831 23.5599 10.1655C23.5599 9.30685 23.5599 8.64127 23.3974 7.99046C23.2497 7.39872 22.9386 6.92512 22.6137 6.56965C22.3025 6.21418 21.9481 5.96314 21.5779 5.8007C21.2077 5.63826 20.8532 5.51907 20.5125 5.46C20.3649 5.44629 20.2172 5.44629 20.0695 5.44629Z" fill="#4D4D4D"/>
<path d="M29.5071 5.47559H24.8164V14.7378H26.7994V11.0534H29.3004V9.3372H26.7994V7.19174H29.5071V5.47559Z" fill="#4D4D4D"/>
<path d="M45.8604 37.0198C45.8456 36.8426 45.6832 34.6972 41.8658 34.7858C38.0633 34.8744 37.1309 35.1117 37.1309 35.1117C37.1309 35.1117 34.2903 32.2268 33.2545 29.9928C33.2545 29.9928 34.5118 26.3084 34.4527 24.0005C34.3937 21.6926 33.8462 20.3604 32.071 20.3751C30.2958 20.3899 30.0437 21.9436 30.2662 24.2515C30.473 26.3231 31.5531 28.7639 31.5531 28.7639C31.5531 28.7639 30.7388 31.3092 29.6439 33.8533C28.5638 36.3986 27.8244 37.7297 27.8244 37.7297C27.8244 37.7297 24.1547 38.9575 22.5715 40.4374C20.9882 41.9173 20.3374 43.0565 21.1654 44.1956C21.8901 45.1724 24.4058 45.3939 26.6694 42.4352C28.9182 39.4765 29.9392 37.6263 29.9392 37.6263C29.9392 37.6263 33.3863 36.6791 34.4517 36.4281C35.517 36.1771 36.8186 35.9693 36.8186 35.9693C36.8186 35.9693 39.9556 39.1358 42.9882 39.0176C46.0239 38.8995 45.8751 37.1981 45.8604 37.0198ZM21.9502 43.6936C20.0716 42.5691 25.9004 39.0915 26.9658 38.9733C26.9658 38.9733 23.9332 44.877 21.9502 43.6936ZM30.917 23.4224C30.917 21.5881 31.5088 21.0998 31.9676 21.0998C32.4265 21.0998 32.9444 21.3213 32.9591 22.9045C32.9739 24.4878 31.9676 27.5952 31.9676 27.5952C31.6269 27.225 30.917 25.243 30.917 23.4224ZM33.3578 35.9555C31.4634 36.4144 30.5173 36.9028 30.5173 36.9028C30.5173 36.9028 30.5173 36.9028 31.2862 35.1718C32.0552 33.4409 32.8547 31.0729 32.8547 31.0729C33.9201 33.0707 36.0507 35.4229 36.0507 35.4229C36.0507 35.4229 35.2523 35.4819 33.3578 35.9555ZM37.9009 35.7921C37.9009 35.7921 44.0556 34.6824 44.0556 36.7836C44.0556 38.87 40.2383 38.0114 37.9009 35.7921Z" fill="#DC1D00"/>
<path d="M42.0859 0.725586V12.0594H52.962L42.0859 0.725586Z" fill="#949494"/>
<path d="M42.2344 0.578125V11.9119H53.1093L42.2344 0.578125Z" fill="#F1F1F1"/>
<path d="M12.8369 9.7373C12.7778 9.7373 12.7187 9.7373 12.6597 9.7373V6.88196H13.0299C13.4592 6.88196 13.725 7.01487 13.918 7.23743C14.1543 7.50324 14.2735 7.97684 14.2735 8.31754C14.2735 8.79115 14.2735 9.22045 13.8442 9.53056C13.6216 9.693 13.2514 9.7373 12.8369 9.7373ZM13.2071 5.31348C13.1923 5.31348 13.1628 5.31348 13.148 5.31348C12.9561 5.31348 12.8221 5.31348 12.7483 5.31348H10.6914V14.5757H12.6744V11.4535L13.1185 11.483C13.5773 11.483 13.9919 11.3796 14.3611 11.2615C14.7313 11.1433 15.0414 10.9356 15.3083 10.6993C15.5741 10.463 15.8262 10.1666 15.9591 9.8259C16.1658 9.308 16.2101 8.58335 16.1658 8.08021C16.1067 7.57707 16.092 7.16254 15.9443 6.83766C15.7966 6.51173 15.6036 6.24592 15.3821 6.03918C15.1458 5.83244 14.8937 5.68371 14.6279 5.58034C14.3463 5.47697 14.0952 5.4179 13.8294 5.3736C13.5921 5.32824 13.3843 5.31348 13.2071 5.31348Z" fill="#FFFEFE"/>
<path d="M19.5706 12.8892C19.5115 12.8892 19.4524 12.8892 19.3786 12.8892V6.98545C19.3934 6.98545 19.3934 6.98545 19.4081 6.98545C19.8079 6.98545 20.311 6.98545 20.5621 7.19219C20.8279 7.39893 21.0357 7.6658 21.1834 7.96114C21.331 8.27231 21.4344 8.61195 21.4492 8.98218C21.4639 9.41149 21.4492 9.75113 21.4492 10.0475C21.4492 10.3292 21.4492 10.6836 21.3901 11.039C21.331 11.3945 21.2129 11.7046 21.0642 12.0158C20.9017 12.3122 20.6201 12.5189 20.3691 12.7109C20.1476 12.8449 19.8807 12.8892 19.5706 12.8892ZM19.925 5.29883C19.7183 5.29883 19.4957 5.3136 19.3628 5.3136C19.1117 5.32836 18.963 5.32836 18.9039 5.32836H17.3945V14.5905H19.1698C19.9535 14.5905 20.6053 14.4724 21.1528 14.2498C21.7002 14.0283 22.1295 13.7024 22.4692 13.3026C22.7951 12.9029 23.0461 12.4145 23.1938 11.8523C23.3415 11.2901 23.4153 10.6836 23.4153 10.018C23.4153 9.15939 23.4153 8.49381 23.2529 7.843C23.1052 7.25126 22.794 6.77766 22.4692 6.42219C22.158 6.06672 21.8036 5.81568 21.4334 5.65324C21.0631 5.4908 20.7087 5.37161 20.368 5.31254C20.2214 5.29883 20.0737 5.29883 19.925 5.29883Z" fill="#FFFEFE"/>
<path d="M29.3665 5.32812H24.6758V14.5903H26.6588V10.9059H29.1587V9.18974H26.6588V7.04428H29.3665V5.32812Z" fill="#FFFEFE"/>
</svg>

    );
};

export default XpdfIcon;
