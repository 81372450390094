import axios, { AxiosInstance } from "axios";
import { useState } from "react";

function createClient(): AxiosInstance {
    //const [access_token, setAccess_token] = useState<any>("");
    let mar_token = null;
    const getMarketPlaceToken: any = () => {
        const url: any = localStorage.getItem("url");
        const decodedUrl: string = decodeURIComponent(url);
        const mktPlaceToken = decodedUrl?.substring(decodedUrl?.indexOf("token") + 6, decodedUrl?.length - 1);
        // mar_token =
        //     "sNUNL8b95aovjb+wbRqNI7pscnCA7FdGX8tQJVoI/wQWUv3IDREs+KWUIgRJ3st3FDyEoYj4aNwzBJ1WyZX5PtvVcrmGQlOM3d+yk6xtaVLRPGD3APZG6+9utYY9THyoAQpTlZG5LqV4ea0Aw403Oj/K267PY05jpg2l8fvY7WJ+1EIEgXcDvDhHjU3u+e4UO/ayNCW2dWOcgHHx/IfZpXO4tVfQQKWX6AI+AdwDo6E";
        mar_token = mktPlaceToken;
        console.log(mar_token);
    };

    getMarketPlaceToken();

    const apiUrl = "https://saas-api.softsensor.ai/users/v1/user/access-token";

    // Make the GET request
    axios
        .get(apiUrl)
        .then((response) => {
           
            localStorage.setItem("access_token", response.data.data.access_token);
        })
        .catch((error) => {
            console.error("Error:", error);
        });


    const apiClient: AxiosInstance = axios.create({
        baseURL: "https://marketplaceapi.microsoft.com/",
        headers: {
            "Access-Control-Allow-Origin": "*",
            //Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiIyMGU5NDBiMy00Yzc3LTRiMGItOWE1My05ZTE2YTFiMDEwYTciLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8zNThiMGI1MC01YmJjLTRmNDMtOWFlOS04NGU1ZGI1OGI3N2IvIiwiaWF0IjoxNjk4OTMyMjM5LCJuYmYiOjE2OTg5MzIyMzksImV4cCI6MTY5ODkzNjEzOSwiYWlvIjoiRTJGZ1lERDQ0NlNUSi9CZG1YdmhsNHN1cjhUNEFBPT0iLCJhcHBpZCI6IjkyODlkMmM3LTRlMWItNDVkMC1hMThiLWJkYTk1MWRlZjUxNyIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzM1OGIwYjUwLTViYmMtNGY0My05YWU5LTg0ZTVkYjU4Yjc3Yi8iLCJvaWQiOiIzMGJiZjU4ZC1iYzI1LTQ2ZjItYTkyYS0wOGVmZTcxOTg2NzMiLCJyaCI6IjAuQVVVQVVBdUxOYnhiUTAtYTZZVGwyMWkzZTdOQTZTQjNUQXRMbWxPZUZxR3dFS2RGQUFBLiIsInN1YiI6IjMwYmJmNThkLWJjMjUtNDZmMi1hOTJhLTA4ZWZlNzE5ODY3MyIsInRpZCI6IjM1OGIwYjUwLTViYmMtNGY0My05YWU5LTg0ZTVkYjU4Yjc3YiIsInV0aSI6ImxBdlBZUVlMQWtldTlKWXpGNWNnQUEiLCJ2ZXIiOiIxLjAifQ.lrqQbnWzdOVQB8MkNLlCU9pjHuGde95kUfxyTYPeR4osQXRjnbfStE7RZlNyqbOcl8M2QMoBJaqBljhw3sdSF7bULD1pTFxwmDPPuMjmCHhfKPjBXl2amqmAY7pt96jZT1R9kONrpUVZ098WgUIzQ3JdsJd7qWifgZ6izpY18TZ1lOLqyQc1KFkO4848pP6gKx6dsZeppCEvyNhwM7P9quNi4eHvhHAl8tov1q3Eb1aheGLOGxgeZQURU94Twha1bns4sIq6YK4IjSiaa8NuXs4eAYoNCXmcAJyCH1ltstx4eL19Angv4Jq5p-YrF-u0LrPFyTJdGUo6kEWISl-oSA`,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "x-ms-marketplace-token": `${mar_token}=`,
            
        },
    });
    return apiClient;
}
createClient();

const apiService = {
    createClient,
};
export default apiService;
