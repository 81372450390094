import React from "react";

const BellIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 20C12.9625 20 13.75 19.2615 13.75 18.359H10.25C10.25 18.7942 10.4344 19.2116 10.7626 19.5194C11.0908 19.8271 11.5359 20 12 20ZM17.25 15.0769V10.9744C17.25 8.45539 15.815 6.34667 13.3125 5.78872V5.23077C13.3125 4.54974 12.7262 4 12 4C11.2737 4 10.6875 4.54974 10.6875 5.23077V5.78872C8.17625 6.34667 6.75 8.44718 6.75 10.9744V15.0769L5 16.7179V17.5385H19V16.7179L17.25 15.0769Z"
                fill="#747474"
            />
        </svg>
    );
};

export default BellIcon;
