import React from "react";

const FinDataLogo = () => {
    return (
        <svg width="56" height="40" viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M184.001 63.4021L106.897 19L107.197 196.308L184.001 151.906V63.4021Z" fill="#165CA4"/>
<path d="M106.897 41.8018V63.4028L107.197 107.205V174.108L164.5 141.106V74.2033L106.897 41.8018Z" fill="white"/>
<path d="M126.399 141.106V74.8025L145.6 85.003V129.405L126.399 141.106Z" fill="#165CA4"/>
<path d="M106.904 19.001V42.1021L107.204 196.009L31 151.907V63.1031L106.904 19.001Z" fill="#2085B2"/>
<path d="M49.8926 141.406V74.5033L106.895 41.8018V63.4028L69.0935 85.6038V107.205L87.9944 97.0044L106.895 107.205L69.0935 129.106V152.207L49.8926 141.406Z" fill="white"/>
</svg>

    );
};

export default FinDataLogo;
