import { Add } from "@mui/icons-material";
import {
    Box,
    Button,
    IconButton,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import EditIcon from "../../components/Common/svgLogs/EditIcon";
import { Context } from "../../context/setting";
import moment from "moment";

const ChatHistory = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [historyList, setHistoryList] = useState<any[]>(
        Object?.keys(contextStore?.chats)?.map((item) => contextStore?.chats[item]) || []
    );

    const showConfirmation = (fileName:any) => {
        return window.confirm(`FileName: "${fileName}" ! Are you sure to Delete the Selected Document  ?`);
      };

   

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Typography variant="h6" fontWeight={600}>
                        Chat History
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <OutlinedInput
                        sx={{
                            pr: "1.8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "7.6px",
                                borderColor: "#CFCFCF",
                                overflow: "hidden",
                            },
                            "& .MuiOutlinedInput-input": {
                                height: "0.5rem",
                                padding: "14px 12px",
                            },
                        }}
                        placeholder="Search"
                        endAdornment={
                            <IconButton sx={{ borderRadius: "4px" }}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.91732 15.4245C10.3225 15.4242 11.6872 14.9538 12.7941 14.0881L16.2743 17.5683L17.3937 16.4489L13.9135 12.9687C14.7796 11.8617 15.2503 10.4967 15.2507 9.09115C15.2507 5.5991 12.4094 2.75781 8.91732 2.75781C5.42528 2.75781 2.58398 5.5991 2.58398 9.09115C2.58398 12.5832 5.42528 15.4245 8.91732 15.4245ZM8.91732 4.34115C11.5369 4.34115 13.6673 6.47152 13.6673 9.09115C13.6673 11.7108 11.5369 13.8411 8.91732 13.8411C6.29769 13.8411 4.16732 11.7108 4.16732 9.09115C4.16732 6.47152 6.29769 4.34115 8.91732 4.34115Z"
                                        fill="#484848"
                                    />
                                </svg>
                            </IconButton>
                        }
                    />
                    <Button
                        startIcon={<Add />}
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            px: 2,
                            borderRadius: "7.31px",
                            "&:hover": { background: "#007BFFc3" },
                        }}
                        onClick={() => navigate("/chat_history/new_chat")}
                    >
                        New Chat
                    </Button>
                </Box>
            </Box>
            <Box sx={{ "& *": { borderColor: "#F3F3F4 !important" } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {["Chat Name", "Data Source", "Created", "Last Used", "Status", ""]?.map((elem) => (
                                <TableCell
                                    sx={{ borderBottomWidth: "2px", pl: 3, width: elem === "" ? "5rem" : "auto" }}
                                >
                                    <Typography variant="caption">{elem}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyList?.map((item) => (
                            <TableRow>
                                {/* {["userName", "source", "created", "lase_used", "status", ""]?.map((elem: string) =>
                                    elem === "" ? (
                                        <TableCell>
                                            <EditIcon />
                                            <DeleteIcon />
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            sx={{
                                                pl: 3,
                                                color:
                                                    elem === "status"
                                                        ? item[elem] === "Active"
                                                            ? "#3A8A14"
                                                            : "#CC5555"
                                                        : "",
                                            }}
                                        >
                                            {elem === "status" ? (
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Box
                                                        style={{
                                                            width: "8px",
                                                            height: "8px",
                                                            borderRadius: "50%",
                                                            background: item[elem] === "Active" ? "#3A8A14" : "#CC5555",
                                                        }}
                                                    ></Box>
                                                    <span>{item[elem]}</span>
                                                </Box>
                                            ) : (
                                                item[elem]
                                            )}
                                        </TableCell>
                                    )
                                )} */}
                                <TableCell
                                    sx={{
                                        pl: 3,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setContext({ ...contextStore, selected: item["userName"] });
                                        navigate("/bond_llm");
                                    }}
                                >
                                    {item["userName"]}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pl: 3,
                                    }}
                                >
                                    Document
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pl: 3,
                                    }}
                                >
                                    {moment(item["created"])?.format("dddd")}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pl: 3,
                                    }}
                                >
                                    {moment(item["lase_used"])?.format("dddd")}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pl: 3,
                                        color: item["status"] === "active" ? "#3A8A14" : "#CC5555",
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Box
                                            style={{
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                background: item["status"] === "active" ? "#3A8A14" : "#CC5555",
                                            }}
                                        ></Box>
                                        <span>{item["status"]}</span>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        {/* <EditIcon /> */}
                                        <Button> <DeleteIcon color={"#CC5555"} /></Button>

                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {historyList?.length === 0 && (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 8 }}>
                        <Typography variant="caption" sx={{ fontSize: "1rem" }}>
                            Recent chats are cleared, Please create new chat.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ChatHistory;
