import { Box, IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import AttachmentIcon from "../../components/Common/svgLogs/AttachmentIcon";
import MongoDBColorIcon from "../../components/Common/svgLogs/MongoDBColorIcon";
import SqlIcon from "../../components/Common/svgLogs/SqlIcon";
import PostgresIcon from "../../components/Common/svgLogs/PostgresIcon";
import GoogleDocsIcon from "../../components/Common/svgLogs/GoogleDocsIcon";
import GoogleDriveIcon from "../../components/Common/svgLogs/GoogleDriveIcon";
import GoogleMailIcon from "../../components/Common/svgLogs/GoogleMailIcon";
import Upcoming1Icon from "../../components/Common/svgLogs/Upcoming1Icon";
import EllipseIcon from "../../components/Common/svgLogs/EllipseIcon";
import Upcoming2Icon from "../../components/Common/svgLogs/Upcoming2Icon";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import ExcelIcon from "../../components/Common/svgLogs/ExcelIcon";
import XpdfIcon from "../../components/Common/svgLogs/XpdfIcon";
import React, { ChangeEvent } from 'react';
//import pdfjs from 'pdfjs-dist/webpack.mjs';
import  {  getDocument } from 'pdfjs-dist';

const AddSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);  
    const [pageCount, setPageCount] = useState(0);
    
      
    const navigate = useNavigate();

   

    const handleFileInput = async (event:any) => {
    const file = event.target.files[0];
    alert(file); console.log(file);
    if (file) {
      
      const response = await fetch(file);
      alert(response); console.log(response);
      const arrayBuffer = await response.arrayBuffer();
      alert(arrayBuffer); console.log(arrayBuffer);

      // Create a PDFJS Document object
      const pdf = await getDocument(arrayBuffer).promise;
      alert(pdf); console.log(pdf);

      // Get the total number of pages
      const numPages = pdf.numPages;
      console.log('Number of pages:', numPages);
    }
    alert('3');
   // setContextAndNavigate(file);



  };

 
  

  const setContextAndNavigate = (file: File | null) => {
    alert('lets move');
    setContext({ ...contextStore, source_file: file });
    file && navigate("/data_source/add_detail");
  };


    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={() => navigate(contextStore?.previousRoute || "/data_source/main")}>
                    <LessThanIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600}>
                    Add Data Source
                </Typography>
            </Box>
            <Box sx={{ display: "flex", px: 1.5, gap: 8 }}>
                <Box sx={{ width: "58%" }}>
                    <Typography variant="subtitle2">Upload Documents</Typography>
                    <Box
                        sx={{
                            height: "26rem",
                            border: "1px dashed #007BFF",
                            borderRadius: "14px",
                            mt: 3,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        component={"label"}
                    >
                        <input
                            hidden
                            type="file"
                            accept=".pdf, .xls, .xlsx"

                            //onChange={handleFileInput}
                            
                            onChange={(e: { target: { files: any } }) => {
                                //alert('1');
                                setContext({ ...contextStore, source_file: e?.target?.files[0] });
                                e?.target?.files[0] && navigate("/data_source/add_detail");
                            }}

                        />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
                            <UploadBoxIcon />
                            <Typography variant="subtitle2">
                                Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                            </Typography>
                            <Typography variant="caption">Supported .pdf .txt. csv. docx or xlsx</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "40%" }}>
                    <Typography variant="subtitle2">Data Source Types</Typography>
                    <Box sx={{ mt: 3, display: "flex", gap: 5, flexWrap: "wrap" }}>
                        {[
                            //{ title: "Website", icon: AttachmentIcon },
                            { title: "Excel", icon: ExcelIcon },
                            { title: "PDF", icon: XpdfIcon },
                            //{ title: "MongoDB", icon: MongoDBColorIcon },
                            //{ title: "SQL", icon: SqlIcon },
                            //{ title: "PostgresSQL", icon: PostgresIcon },
                            //{ title: "Google Docs", icon: GoogleDocsIcon },
                        ]?.map((elem) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 4px 8px 0px #AAAAAA1A",
                                    width: "13rem",
                                    height: "4.5rem",
                                    borderRadius: "14px",
                                    gap: 3,
                                    pl: 4,
                                }}
                            >
                                <elem.icon />
                                <Typography variant="subtitle2">{elem?.title}</Typography>
                            </Box>
                        ))}
                    </Box>
                    {/* <Box sx={{ mt: 5 }}>
                        <Typography variant="subtitle2">Upcoming</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 4, mt: 2 }}>
                            <GoogleDriveIcon />
                            <GoogleMailIcon />
                            <Upcoming1Icon />
                            <EllipseIcon />
                            <Upcoming2Icon />
                        </Box>
                    </Box> */}
                </Box>
            </Box>
        </Box>
    );
};

export default AddSource;
