import axios from "axios";
import { apiPromise } from "./helpers";

let instance = axios.create({
    headers: { "Content-Type": "application/json; charset=utf-8" },
});

export class Api {
    static baseUrl: string = "https://demo-bc-api.softsensor.ai/bond_llm/namespaces";
    static socketUrl: string = "wss://demo-bc-api.softsensor.ai/bond_llm/chat_bot";
    static upload: string = "https://demo-bc-api.softsensor.ai/bond_llm/azure_upload_pdf";
    //static upload: string = "https://demo-bc-api.softsensor.ai/bond_llm/azure_upload_pdf?" + "mail_id=" + username + "&plan_id=" + "plan2-testing" ;
    static createUser: string = "https://saas-api.softsensor.ai/users/v1/user";
    static uploadUserData: string = "https://saas-api.softsensor.ai/dimensions/v1/dimension";
    //static upload:any = null;

    static getUsers() {
        return apiPromise(() => instance.get("https://jsonplaceholder.typicode.com/users"));
    }

    static getPdfs() {
        const account = localStorage.getItem("account_user");
         let account_info:any = null;
        if (account !== null) {
            account_info = JSON.parse(account);
        }
        // return apiPromise(() => instance.get(this.baseUrl + "/namespaces"));
        return apiPromise(() => instance.get(`${this.baseUrl}?mail_id=${account_info.username}`));
    }

    // static uploadPdf(formData: FormData) {
    //     return apiPromise(() =>

    //         instance.post(this.upload, formData, {

    //             headers: { "Content-Type": "multipart/form-data" },
    //         })
    //     );
    // }

    static uploadPdf(formData: FormData) {
        //alert(username + "hello");
        const account = localStorage.getItem("account_user");
        let account_info = null;
        if (account !== null) {
            account_info = JSON.parse(account);
        }
        //alert(account_info);
        //alert(account);

        const uploadUrl = `${this.upload}?mail_id=${account_info.username}&plan_id=${account_info.plan_id}&resource_id=${account_info.subs_id}`;

        return apiPromise(() =>
            instance.post(uploadUrl, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static addUserInfoToDB(data: any) {
        return apiPromise(() => instance.post(this.uploadUserData, data));
    }

    static getUserInfo(email: string) {
        return apiPromise(() => instance.get(`https://saas-api.softsensor.ai/dimensions/v1/dimension/${email}`));
    }

    static createNewUser(data: any) {
        return apiPromise(() => instance.post(this.createUser, data));
    }
}
