import React, { useContext, useEffect, useState } from "react";

import { Add } from "@mui/icons-material";
//import SonftSensorMiniXIcon from "../../components/Common/svgLogs/SonftSensorMiniXIcon";
import { useNavigate } from "react-router-dom";
import FinData from "../assets/FinData";
import LandinglogoIcon from "../components/Common/svgLogs/LandinglogoIcon";
import FinDataLogo from "../assets/FinDataLogo";
import {
    AppBar,
    Avatar,
    Box,
    Tab,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import LoginPage from "../Login";
import { Grid } from "@mantine/core";
import AzureAuthentication from "../screens/Login_page/azure_login";


const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Account_Page = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    //const navigate = useNavigate();
    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <AzureAuthentication />
          
            {/* <LoginPage /> */}
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default Account_Page;
